// h/t https://stackoverflow.com/a/8358141
// Updated to my OCD levels
export default function formatPhone(phoneNum) {
	const cleaned = `${phoneNum}`.replace(/\D/g, '');
	if (!cleaned) {
		return '';
	}
	let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (!match) {
		return '';
	}
	const intlCode = ''; // match[1] ? '+1 ' : '';
	return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
}
