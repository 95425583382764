import React from 'react';
import styles from './SimpleBanner.module.scss';

export function SimpleBanner({ style }) {
	return (
		<>
			<div className={`${styles.banner} SimpleBanner-root`} style={style}>
				<span className={styles.d}>C</span>
				<span className={styles.ecidr}>hat</span>
				<span className={styles.d}>B</span>
				<span className={styles.ecidr}>etter</span>
			</div>
		</>
	);
}
