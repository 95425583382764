import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function CustomSkeleton({ active = true, children, ...props }) {
	if (!active) {
		return children;
	}
	return (
		<Skeleton animation="pulse" {...props}>
			{children}
		</Skeleton>
	);
}
