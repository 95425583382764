import clamp from './clamp';

export default function exponentialDelayFactory({
	initialDelay = 500,
	maxDelay = 5000,
	multiplier = 1.33,
} = {}) {
	let attempt = 0;
	const generator = () => {
		attempt += 1;
		const delay = Math.floor(
			clamp(initialDelay * multiplier ** (attempt - 1), initialDelay, maxDelay),
		);

		// console.warn(`exponentialDelayFactory: attempt=${attempt}, delay=${delay}`);
		return delay;
	};

	generator.reset = () => {
		attempt = 0;
	};

	return generator;
}
