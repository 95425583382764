// The messages pane and infinite scroll was based on the example
// given in this article: https://medium.com/@tiagohorta1995/dynamic-list-virtualization-using-react-window-ab6fbf10bfb2

import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	createRef,
} from 'react';
import marked from 'marked';

import Measure from 'react-measure';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import clsx from 'clsx';
import AppConfig from 'shared/config';
import { ServerStore } from 'utils/ServerStore';
import { CircularProgress } from '@material-ui/core';
import formatTimestamp from 'shared/utils/formatTimestamp';
import styles from './MessageList.module.scss';
import PersonAvatar from '../../PersonAvatar';
import MoreMenu from '../../MoreMenu';

const authorChanged = (
	{ person: { id: otherPersonId } = {} } = {},
	{ id: currentPersonId } = {},
) => !otherPersonId || currentPersonId !== otherPersonId;

const authorChangedDebug = (
	{ person: { id: otherPersonId } = {} } = {},
	{ id: currentPersonId } = {},
) => `${!otherPersonId} || ${currentPersonId} !== ${otherPersonId}`;

const subjectChanged = (
	{ channelData: { subject: previousSubject } = {} } = {},
	subject,
) =>
	previousSubject &&
	subject &&
	`${subject}`.replace(/re:\s*/gi, '') !== previousSubject;

// Show timestamp if diff is more than X minutes (15 min right now...)
const TIMESTAMP_CHANGE_VALUE = 1000 * 60 * 15;
const showTimestamp = ({ timestamp: previousTimestamp } = {}, timestamp) =>
	!previousTimestamp ||
	new Date(timestamp) - new Date(previousTimestamp) > TIMESTAMP_CHANGE_VALUE;

const MyLink = React.forwardRef(({ children, ...props }, ref) => (
	<a rel="noopener noreferrer" target="_blank" {...props} ref={ref}>
		{children}
	</a>
));

const fileAttachmentUrl = (attachmentId, name) =>
	`//${AppConfig.apiHost}/attachments/${attachmentId}/${name}?token=${
		ServerStore.server().token
	}`;

const Message = React.forwardRef(
	(
		{
			onSizeChanged = () => {},
			setLastSeenMessage = () => {},
			previousMessage,
			nextMessage,
			lastSeenMessageId,
			message: {
				// id: messageId,
				inbound,
				text,
				attachments,
				channelType,
				channelData: { subject } = {},
				person,
				status,
				// statusMessage,
				timestamp,
				loading,
			},
		},
		ref,
	) => {
		const atts = Array.from(attachments || []);

		const imageAttachments = atts.filter(({ mimeType }) =>
			`${mimeType}`.startsWith('image/'),
		);

		const videoAttachments = atts.filter(({ mimeType }) =>
			`${mimeType}`.startsWith('video/'),
		);

		const fileAttachments = atts.filter(
			({ mimeType }) =>
				!`${mimeType}`.startsWith('image/') &&
				!`${mimeType}`.startsWith('video/'),
		);

		const isNewMessage =
			lastSeenMessageId &&
			previousMessage &&
			previousMessage.id === lastSeenMessageId;

		const scaledSize = (width, height, propKey) => {
			if (!width || !height) {
				return undefined;
			}

			// Matches max width/height for <img> in the css
			const maxWidth = 320;
			const maxHeight = 240;

			const origAspect = width / height;
			const scaledWidth = origAspect >= 1 ? maxWidth : maxHeight * origAspect;
			const scaledHeight = origAspect >= 1 ? maxWidth / origAspect : maxHeight;
			const sizeProps = {
				width: scaledWidth,
				height: scaledHeight,
			};

			// console.log('scaledSize', {
			// 	origAspect,
			// 	scaledWidth,
			// 	scaledHeight,
			// 	width,
			// 	height,
			// });

			const prop = sizeProps[propKey || 'width'];
			if (Number.isNaN(prop)) {
				return undefined;
			}

			return prop;
		};

		const [menuVisible, setMenuVisible] = useState();
		const toggleMoreMenu = () => {
			setMenuVisible(!menuVisible);
		};

		const onMarkUnread = () => {
			// console.log('previous:', previousMessage);
			setLastSeenMessage(previousMessage);
		};

		const onDeleteMessage = () => {
			// TODO
		};

		// console.log({ isNewMessage, lastSeenMessageId, curPrevMsg: previousMessage.id })
		return (
			<div
				ref={ref}
				className={clsx(
					styles.message,
					inbound ? styles.inbound : styles.outbound,
					// TODO: other styles based on status?
				)}
			>
				<Measure bounds onResize={onSizeChanged}>
					{({ measureRef }) => (
						<div className={styles.bodyWrap} ref={measureRef}>
							{isNewMessage ? (
								<div className={styles.newMessage}>
									<div className={styles.label}>Unread</div>
									<div className={styles.line}></div>
								</div>
							) : (
								''
							)}

							{showTimestamp(previousMessage, timestamp) ? (
								<div className={styles.timestampHeader}>
									<div className={styles.label}>
										{/* Sunday, March 27th &middot; 2:30 PM */}
										{formatTimestamp(timestamp)}
									</div>
								</div>
							) : (
								''
							)}

							{loading ? (
								<div className={styles.loading}>
									<CircularProgress />
									<label>Loading more messages ...</label>
								</div>
							) : (
								<div
									className={clsx(
										styles.avatarWrap,
										authorChanged(previousMessage, person) && styles.newAuthor,
										authorChanged(nextMessage, person) && styles.newAuthorEnd,
									)}
									onClick={toggleMoreMenu}
									onMouseLeave={() => setMenuVisible(false)}
									data-prev-msg-debug={`${authorChanged(
										previousMessage,
										person,
									)}=${authorChangedDebug(previousMessage, person)}`}
									data-next-msg-debug={`${authorChanged(
										nextMessage,
										person,
									)}=${authorChangedDebug(nextMessage, person)}`}
								>
									<Tooltip
										title={`${person.name} at ${new Date(
											timestamp,
										).toLocaleString()} (${channelType}: ${status})`}
									>
										<div
											className={clsx(
												styles.avatar,
												// Use styles to show/hide rather than remove element,
												// because we were getting weird layout issues
												// with no child element when not showing avatar
												// even tho the .avatar had width set on the outer element
												// so now we just do opacity 0/1 to hide, which fixes layout
												authorChanged(nextMessage, person) && styles.showAvatar,
											)}
										>
											<PersonAvatar person={person} inbound={inbound} />
										</div>
									</Tooltip>

									<div className={styles.contentWrap}>
										{/* {authorChanged(previousMessage, person) ? (
										<div className={styles.whoAndWhen}>
											<div className={styles.who}>{person.name}</div>
											<Tooltip
												title={
													timestamp
														? new Date(timestamp).toLocaleString()
														: 'New'
												}
											>
												<div className={styles.when}>
													{formatTimestamp(timestamp)}
												</div>
											</Tooltip>
											<Tooltip
												title={`${status || channelType || 'New'}${
													status === 'error' && statusMessage
														? `: ${statusMessage}`
														: ''
												}`}
											>
												<div className={styles.channel}>
													{`(${channelType})` || ''}
													{status === 'sending' ? ': Sending...' : ''}
												</div>
											</Tooltip>
										</div>
									) : (
										''
									)} */}

										{subject &&
										(authorChanged(previousMessage, person) ||
											subjectChanged(previousMessage, subject)) ? (
											<div className={styles.subject}>{subject}</div>
										) : (
											''
										)}

										<Tooltip
											title={`${person.name} at ${new Date(
												timestamp,
											).toLocaleString()} (${channelType}: ${status})`}
										>
											<div className={styles.textContentWrap}>
												<div className={styles.mediaAttachments}>
													{imageAttachments.map(
														({ id, name, width, height }) => (
															<a
																key={id}
																href={fileAttachmentUrl(id, name)}
																target="_blank"
																rel="noopener noreferrer"
															>
																<img
																	alt={name}
																	// src={fileAttachmentUrl(id, name)}
																	src={fileAttachmentUrl(id, name)}
																	width={scaledSize(width, height, 'width')}
																	height={scaledSize(width, height, 'height')}
																/>
															</a>
														),
													)}
													{videoAttachments.map(({ id, name }) => (
														<video
															key={id}
															alt={name}
															src={fileAttachmentUrl(id, name)}
															// Not using scaledSize() helper with videos
															// because we want to maintain landscape A/R
															// so that the browser video
															// controls render completely
															width={240 * 1.7}
															height={240}
															controls={true}
														/>
													))}
												</div>
												{text ? (
													<div className={styles.textContent}>
														{channelType === 'email' && !inbound ? (
															<div
																className={styles.emailBodyHtml}
																dangerouslySetInnerHTML={{
																	__html: marked(text),
																}}
															/>
														) : (
															<div className={styles.emailBodyHtml}>
																<p>{text}</p>
															</div>
														)}
													</div>
												) : (
													''
												)}
											</div>
										</Tooltip>

										{fileAttachments.length ? (
											<div className={styles.fileAttachments}>
												{fileAttachments.map(({ id, name }) => (
													<ButtonBase
														className={styles.attachment}
														key={id}
														alt={name}
														href={fileAttachmentUrl(id, name)}
														component={MyLink}
													>
														<div className={styles.icon}>
															<AttachFileIcon />
														</div>
														<span className={styles.label}>{name}</span>
													</ButtonBase>
												))}
											</div>
										) : (
											''
										)}
									</div>

									<MoreMenu
										className={clsx(
											styles.moreMenu,
											menuVisible && styles.forceVisible,
										)}
										tooltip="Actions related to this message"
										actions={[
											{
												text: 'Mark unread from here',
												onClick: onMarkUnread,
											},
											{
												text: 'Delete Message',
												onClick: onDeleteMessage,
											},
										]}
									/>
								</div>
							)}
						</div>
					)}
				</Measure>
			</div>
		);
	},
);

const MessageList = ({
	messages,
	onMoreMessagesNeeded,
	hasMoreMessages,
	scrollPositionRef = {
		current: {},
	},
	lastSeenMessageId,
	// just for testing
	// id: 'b80c87bd-2edf-4bae-8975-04f68f92d328'
	onLastSeenChanged,
}) => {
	// References
	const listRef = useRef({});
	const rowHeights = useRef({});

	const innerListRef = createRef();
	const listOuterHeight = createRef();

	const userHasScrolled = useRef(false);
	const loadTimeoutExpired = useRef();

	function getRowHeight(index) {
		return rowHeights.current[index] + 0 || 82;
	}

	function setRowHeight(index, size) {
		if (listRef.current) {
			listRef.current.resetAfterIndex(0);
		}
		rowHeights.current = { ...rowHeights.current, [index]: size };
	}

	const scrollToBottom = useCallback(() => {
		if (listRef.current && innerListRef.current) {
			listRef.current.scrollToItem(messages.length - 1, 'end');

			// listRef.current.scrollTo(innerListRef.current.scrollHeight);
			// Hackish, I know, but .scrollTo() wasn't doing it...
			listRef.current._outerRef.scrollTop = innerListRef.current.scrollHeight;

			// console.log(innerListRef.current, listRef.current._outerRef);
		}
	}, [innerListRef, messages.length]);

	const restoreScrollPosition = useCallback(() => {
		const {
			current: { offset: lastKnownOffset, scrollHeight: lastKnownScrollHeight },
		} = scrollPositionRef;
		const newScrollHeight = innerListRef.current.scrollHeight;
		const heightDiff = newScrollHeight - lastKnownScrollHeight;
		const newScrollOffset = lastKnownOffset + heightDiff;
		// console.log(`restore scroll position:`, {
		// 	newScrollHeight,
		// 	heightDiff,
		// 	newScrollOffset,
		// 	lastKnownScrollHeight,
		// 	lastKnownOffset,
		// });
		if (listRef.current && listRef.current.scrollTo) {
			listRef.current.scrollTo(newScrollOffset);
		}
	}, [innerListRef, scrollPositionRef]);

	useEffect(() => {
		let tid;
		// TODO:   Maintain scroll position when new messages added
		if (messages.length > 0) {
			// Scroll after a bit on first load to allow images to load
			// console.log(
			// 	`* on mount, scrollPositionRef.current.scrollProgress=`,
			// 	scrollPositionRef.current.scrollProgress,
			// );
			if (
				!userHasScrolled.current ||
				scrollPositionRef.current.scrollProgress > 0.9
			) {
				scrollToBottom();
				if (
					!scrollPositionRef.current.scrollProgress ||
					!scrollPositionRef.current.firstLoadDone
				) {
					tid = setTimeout(scrollToBottom, 750);
					// eslint-disable-next-line no-param-reassign
					scrollPositionRef.current.firstLoadDone = true;
				}
			} else {
				tid = setTimeout(restoreScrollPosition(), 500);
			}
			// If rows resize within this time, scroll to bottom as well
			setTimeout(() => {
				loadTimeoutExpired.current = true;
			}, 2000);
		}

		return () => clearTimeout(tid);
	}, [
		innerListRef,
		messages,
		restoreScrollPosition,
		scrollPositionRef,
		scrollToBottom,
	]);

	const setLastSeenMessage = (message) => {
		if (onLastSeenChanged) {
			onLastSeenChanged(message, { manual: true });
		}
	};

	function Row({ index, style, isScrolling }) {
		const rowRef = useRef({});

		useEffect(() => {
			if (rowRef.current) {
				setRowHeight(index, rowRef.current.clientHeight);
			}
		}, [index, rowRef]);

		return (
			<div style={style}>
				<Message
					onSizeChanged={() => {
						if (rowRef.current) {
							setRowHeight(index, rowRef.current.clientHeight);

							if (!loadTimeoutExpired.current) {
								// scrollToBottom();
								// restoreScrollPosition();
							}
						}
					}}
					previousMessage={messages[index - 1]}
					nextMessage={messages[index + 1]}
					lastSeenMessageId={lastSeenMessageId}
					message={messages[index]}
					ref={rowRef}
					setLastSeenMessage={setLastSeenMessage}
					isScrolling={isScrolling}
				/>
			</div>
		);
	}

	const onScroll = useCallback(
		({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
			const scrollHeight = innerListRef.current.scrollHeight || -1;
			const scrollProgress =
				scrollOffset / (scrollHeight - listOuterHeight.current);

			// eslint-disable-next-line no-param-reassign
			scrollPositionRef.current = {
				offset: scrollOffset,
				scrollHeight,
				scrollProgress,
				firstLoadDone: (scrollPositionRef.current || {}).firstLoadDone,
			};

			if (!userHasScrolled.current && scrollUpdateWasRequested) {
				userHasScrolled.current = true;
				// console.warn(`* User interaction, not forcing scroll to bottom now`);

				if (onLastSeenChanged) {
					onLastSeenChanged(messages[messages.length - 1]);
				}
			}

			// eslint-disable-next-line no-console
			// console.log(`onScroll:`, {
			// 	// scrollDirection,
			// 	// scrollOffset,
			// 	// // scrollUpdateWasRequested,
			// 	// // innerListRef: innerListRef.current.scrollHeight,
			// 	// scrollProgress, // : `${(scrollProgress * 100).toFixed(2)}%`,
			// 	// hasMoreMessages,
			// 	// onMoreMessagesNeeded,
			// 	scrollPositionRef: scrollPositionRef.current,
			// });

			// restoreScrollPosition();

			// TODO: Load older messages when:
			//  - scrollDirection === 'backward' &&
			//  - scrollProgress <= approx .20

			if (
				scrollDirection === 'backward' &&
				scrollProgress <= 0.75 &&
				hasMoreMessages &&
				onMoreMessagesNeeded
			) {
				onMoreMessagesNeeded();
			}
		},
		[
			hasMoreMessages,
			innerListRef,
			listOuterHeight,
			messages,
			onLastSeenChanged,
			onMoreMessagesNeeded,
			scrollPositionRef,
		],
	);

	return (
		<AutoSizer className={styles.scrollContainer}>
			{({ height, width }) => {
				listOuterHeight.current = height;
				return (
					<List
						useIsScrolling
						className={clsx(
							styles.messagesList,
							styles.messengerStyle,
							// styles.slackStyle,
						)}
						height={height - 1}
						itemCount={messages.length}
						itemSize={getRowHeight}
						ref={listRef}
						width={width}
						onScroll={onScroll}
						innerRef={innerListRef}
					>
						{Row}
					</List>
				);
			}}
		</AutoSizer>
	);
};

export default MessageList;
