import { useRef } from 'react';
import { useRemoteData } from './useRemoteData';

export default function useAsyncParam(param, callback) {
	if (!callback) {
		// Must assign callback here instead of in args
		// because we want to capture 'param'
		// eslint-disable-next-line no-param-reassign
		callback = async () => param;
	}

	const asyncState = useRemoteData(() => callback(param));
	const lastValueRef = useRef(param);

	if (lastValueRef.current !== param) {
		lastValueRef.current = param;

		asyncState.reloadData(() => callback(param));
	}

	return asyncState || {};
}
