import React from 'react';
import HubService from '../utils/hub/HubService';

export default function SSLReroutePage() {
	const nextUrl = HubService.getSSLPage();

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
				width: '100%',
				minHeight: '80%',
				// color: 'white',
				padding: '2rem',
			}}
		>
			<h1>One more thing...</h1>
			<p>
				Since we're still in development mode, you also need to accept the SSL
				certificate for the Hub. You'll only need to do this once.
			</p>
			<p>
				Click <a href={nextUrl}>{nextUrl}</a> to accept the SSL certificate.
			</p>
			<small>
				(After clicking, select <i>Advanced</i> > <i>Proceed to ... (unsafe)</i>
				)
			</small>
		</div>
	);
}
