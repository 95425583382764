export default function normalizePhone(phone) {
	if (!phone) {
		return '';
	}

	if (typeof phone === 'object') {
		if (phone.phone) {
			// eslint-disable-next-line no-param-reassign
			phone.phone = normalizePhone(phone.phone);
		}

		return phone;
	}

	const digitsOnly = `${phone}`.replace(/[^\d]/g, '');
	const digitOne = digitsOnly.length === 10 ? '1' : '';

	return `+${digitOne}${digitsOnly}`;
}
