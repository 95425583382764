import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import CustomSkeleton from './CustomSkelton';
import styles from './CustomButton.module.scss';

export default function CustomButton({
	loading,
	children,
	className,
	...props
}) {
	return (
		<CustomSkeleton active={loading || false}>
			<Button
				className={clsx(styles.root, className, 'CustomButton-root')}
				{...props}
			>
				{children}
			</Button>
		</CustomSkeleton>
	);
}
