/* eslint-disable no-console */
import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';

import { MessageTypes } from 'shared/utils/MessageTypes';
import { ServerStore } from '../../utils/ServerStore';
import { useMessageHook } from '../../utils/hub/HubService';
import useAsyncParam from '../../utils/useAsyncParam';

import styles from './ConversationWidget.module.scss';

import HeaderWidget from '../HeaderWidget';
import MessageList from './MessageList';
import ComposerWidget from './ComposerWidget';

function MessageListWidget({ loading, ...props }) {
	return (
		<div className={styles.listWrapper}>
			{loading ? (
				<div className={styles.loadingWrap}>
					<CircularProgress />
				</div>
			) : (
				''
			)}
			<MessageList {...props} />
		</div>
	);
}

export default function ConversationWidget({
	conversationId,
	hideBackButton,
	doublePaneView,
}) {
	// Used for paging as user scrolls up
	const firstEpoch = useRef();
	const [hasOlderMessages, setHasOlderMessages] = useState();

	const messageBatchSize = 25;
	const messageMeta = useAsyncParam(conversationId, (id) => {
		if (!id) {
			return Promise.resolve({});
		}
		// console.warn(`Requesting messageMeta for ${id}`);
		return ServerStore.GetConversation(id, {
			numMessages: messageBatchSize,
		}).then(
			({
				firstEpoch: originalFirstEpoch,
				hasOlderMessages: originalHasOlderMessages,
				...data
			}) => {
				firstEpoch.current = originalFirstEpoch;
				setHasOlderMessages(originalHasOlderMessages);
				return data;
			},
		);
	});

	console.log(`Got messageMeta:`, messageMeta);
	const {
		convoUser: { lastSeenMessageId: lastSeenMessageIdFromServer } = {},
		conversation: {
			person: { id: personId, email, phoneNum, name: personName } = {},
		} = {},
		messages: originalMessagesList = [],
	} = messageMeta;

	// console.log(`Got convo user info:`, {
	// 	lastSeenMessageId,
	// 	lastSeenAt,
	// 	lastSeenMessageEpoch,
	// 	messageMeta,
	// });

	const loading = !messageMeta.loadDone;
	const titleString = !loading && personName;

	const [messages, setMessages] = useState([]);
	const scrollPositionRef = useRef();

	const [lastSeenMessageId, setLastSeenMessageId] = useState();
	const lastSeenSyncGuard = useRef();
	useEffect(() => {
		if (
			!loading &&
			lastSeenMessageId !== lastSeenMessageIdFromServer &&
			!lastSeenSyncGuard.current
		) {
			setLastSeenMessageId(lastSeenMessageIdFromServer);
			lastSeenSyncGuard.current = true;
		}
	}, [loading, lastSeenMessageId, lastSeenMessageIdFromServer]);

	const loadedRef = useRef();
	useEffect(() => {
		if (
			!loading &&
			loadedRef.current !== conversationId &&
			messages.length !== originalMessagesList.length
		) {
			setMessages(originalMessagesList);
			loadedRef.current = conversationId;
			scrollPositionRef.current = { scrollProgress: 1 };
		}
	}, [messages, originalMessagesList, setMessages, loading, conversationId]);

	const onLastSeenChanged = (
		{ epoch: newLastSeenMessageEpoch, id: newLastSeenMessageId } = {},
		{ manual = false } = {},
	) => {
		const props = {
			conversationId,
			lastSeenMessageId: newLastSeenMessageId || '',
			// Epoch of 0 would make all messages in conversation unread,
			// which is what we want if no message given - which would happen
			// if user marks first msg in convo unread
			lastSeenMessageEpoch: newLastSeenMessageEpoch || 0,
		};
		// console.warn(`UpdateConversationUser using props:`, props);
		ServerStore.UpdateConversationUser(props).then(() => {
			if (manual) {
				window.history.go(-1);
			}
		});
	};

	// console.log(`Messages:`, {
	// 	messages,
	// 	firstEpoch: firstEpoch.current,
	// 	hasOlderMessages,
	// 	scrollPositionRef: scrollPositionRef.current,
	// });

	// TBD if we need this - since we would filter on conversation anyway
	// because auto-subscribed to workspace so get all msgs...
	// useEffect(() => {
	// 	HubService.sendMessage({
	// 		type: MessageTypes.ClientPoolSubscribe,
	// 		pool: 'conversation',
	// 		conversationId,
	// 	});

	// 	return () => {
	// 		HubService.sendMessage({
	// 			type: MessageTypes.ClientPoolUnsubscribe,
	// 			pool: 'conversation',
	// 			conversationId,
	// 		});
	// 	};
	// });

	useMessageHook(({ type, ...data }) => {
		// console.log(`ConversationPage messageHooks got type:`, type, data);

		if (type === MessageTypes.ConversationMessagesReceived) {
			console.warn(`++ Got new message over socket: `, data);

			const { message, conversationId: incomingConversationId } = data;
			if (incomingConversationId !== conversationId) {
				console.warn(
					` // Not for current conversation: `,
					incomingConversationId,
				);
				return true;
			}

			if (
				message.clientMessageId &&
				messages.some(
					({ clientMessageId }) =>
						clientMessageId && clientMessageId === message.clientMessageId,
				)
			) {
				console.warn(
					` // clientMessageId already in list, will use status update when ready`,
				);
				return true;
			}

			messages.push(message);

			// Update unread marker
			onLastSeenChanged(message);

			// copy to make react re-render
			setMessages([...messages]);
			// return true;
		}
		if (type === MessageTypes.MessageStatusUpdated) {
			const {
				conversationId: incomingConversationId,
				messageId,
				status,
				statusMessage,
			} = data;

			console.warn(`++ Got message status update over socket: `, data);

			if (incomingConversationId !== conversationId) {
				console.warn(
					` // Not for current conversation: `,
					incomingConversationId,
				);
				return true;
			}

			const msg = messages.find(({ id }) => id === messageId);
			if (msg) {
				// Updates list by reference, so just update these props
				Object.assign(msg, { status, statusMessage });

				// copy to make react re-render
				setMessages([...messages]);
			} else {
				console.warn(
					`Got status update for message not in our list: ${messageId}`,
				);
			}

			// return true;
		}

		return false; // don't intercept
	});

	const onSendMessage = ({ text, channelType, subject }) => {
		const clientMessageId = uuid();
		const message = {
			id: Date.now(),
			timestamp: new Date(),
			channelType,
			text,
			channelData: { subject: channelType === 'email' ? subject : null },
			person: { name: '...', sending: true },
			status: 'sending',
			clientMessageId,
		};

		messages.push(message);

		// copy to make react re-render
		setMessages([...messages]);

		// Do this here instead of in onLastSeenChanged()
		// because onLastSeenChanged is also used directly by MessageList
		// and if we changed the ID in onLastSeenChanged(),
		// it would "erase" the visible marker as soon as the list loaded.
		setLastSeenMessageId(null);

		ServerStore.SendMessage({
			conversationId,
			text,
			subject,
			channelType,
			clientMessageId,
			// demoChatMode: false,
			demoSendMode: true,
			demoReplyMode: true,
		}).then((newMessage) => {
			console.log('Got message from server:', newMessage);
			const { error } = newMessage;
			if (error) {
				// Remove tmp message we added above
				const list = messages.filter(
					(x) => x.clientMessageId !== message.clientMessageId,
				);
				// copy to make react re-render
				setMessages([...list]);

				// Alert a few ms later to let re-render happen
				// eslint-disable-next-line no-alert
				setTimeout(() => window.alert(error.message || error), 100);
			} else {
				// Update unread marker
				onLastSeenChanged(newMessage);

				Object.assign(message, newMessage);
				setMessages([...messages]);
			}
		});
	};

	const timerRef = useRef();
	const loadingMessageGuard = useRef();
	const onMoreMessagesNeeded = () => {
		if (loadingMessageGuard.current) {
			return;
		}

		loadingMessageGuard.current = true;

		setMessages([{ id: Date.now(), loading: true }, ...messages]);

		// console.log(` - onMoreMessagesNeeded`);
		clearTimeout(timerRef.current);
		// timerRef.current = setTimeout(() => {
		// Render a loading spinner - will go away
		// with next setMessages() call
		// console.log(
		// 	` - onMoreMessagesNeeded ... requesting from epoch: `,
		// 	firstEpoch.current,
		// );
		ServerStore.GetConversation(conversationId, {
			messagesBeforeEpoch: firstEpoch.current,
			numMessages: messageBatchSize,
		}).then(
			({
				messages: newMessages,
				firstEpoch: newFirstEpoch,
				hasOlderMessages: newHasOlderMessages,
			}) => {
				console.warn(` + Got older messages:`, {
					newMessages,
					newFirstEpoch,
					newHasOlderMessages,
				});

				setHasOlderMessages(newHasOlderMessages);
				setMessages([...newMessages, ...messages.filter((x) => !x.loading)]);
				firstEpoch.current = newFirstEpoch;
				loadingMessageGuard.current = false;
			},
		);
		// }, 250);
	};

	const lastMessage =
		(messages && messages.length && messages[messages.length - 1]) || {};

	// Used for onClick handler to mark the convo as "Read" on first interaction
	const firstInteractionGuard = useRef(false);
	const updateLastSeen = () => {
		if (!firstInteractionGuard.current) {
			firstInteractionGuard.current = true;
			onLastSeenChanged(lastMessage);
		}
	};

	const {
		channelType: lastChannelType,
		channelData: { subject: lastSubject } = {},
	} = lastMessage;

	const actionMenu = [
		{
			text: 'Person Details',
			icon: PersonIcon,
			// onClick: onUnfollow,
			component: 'a',
			// rel: 'nofollow noreferrer',
			// target: '_blank',
			href: `#/people/${personId}`,
		},
		// {
		// 	text: 'Share',
		// 	// icon: ShareIcon,
		// 	// onClick: onShare,
		// },
	];

	return (
		<div
			className={clsx(styles.root, doublePaneView && styles.doublePaneView)}
			onClick={updateLastSeen}
		>
			<HeaderWidget
				title={titleString}
				loading={loading}
				actions={actionMenu}
				position={'relative'}
				hideBackButton={hideBackButton || doublePaneView}
				menuTooltip="Actions related to this conversation"
			/>
			<MessageListWidget
				loading={loading}
				messages={messages}
				hasMoreMessages={hasOlderMessages}
				onMoreMessagesNeeded={onMoreMessagesNeeded}
				lastSeenMessageId={lastSeenMessageId}
				onLastSeenChanged={onLastSeenChanged}
				scrollPositionRef={scrollPositionRef}
			/>
			{conversationId && (
				<ComposerWidget
					isEmailAllowed={!!email}
					isSmsAllowed={!!phoneNum}
					loading={loading}
					lastChannelType={lastChannelType}
					lastSubject={lastSubject}
					onSendMessage={onSendMessage}
					conversationId={conversationId}
				/>
			)}
		</div>
	);
}
