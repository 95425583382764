import React, { useEffect } from 'react';
import { NavConfig, NavControlService } from '../PrimaryNav';
import HubService from '../../utils/hub/HubService';
import ConversationWidget from './ConversationWidget';

export default function ConversationPage({ match: { params } }) {
	useEffect(() => {
		// Has to exec inside an effect, otherwise get React rendering
		// errors about not updating a component while rendering this page
		NavControlService.showPrimaryNav(true, NavConfig.conversations);
	});

	// If still loading connection, returns undefined.
	// If loaded but LoginRequired, redirects to /login (leaves this page)
	// If loaded and authorized, returns true
	HubService.useAuthRequired();

	const { id: conversationId } = params;

	return <ConversationWidget conversationId={conversationId} />;
}
