import React from 'react';
import PrivateRoute from 'components/PrivateRoute';
import { Route, Switch } from 'react-router-dom';
import SSLReroutePage from './SSLReroutePage';
import SettingsPage from './SettingsPage/SettingsPage';
import LoginPage from './LoginPage/LoginPage';
import ConversationPage from './ConversationPage/ConversationPage';
import ConversationsListPage from './ConversationsListPage/ConversationsListPage';
import DashboardPage from './DashboardPage/DashboardPage';
import PeopleListPage from './PeopleListPage/PeopleListPage';
import PersonPage from './PersonPage/PersonPage';

// const PersonEditPage = () => <>?</>;

const RouteDefinitions = {
	'/ssl-reroute': { component: SSLReroutePage },

	'/': { component: LoginPage },
	'/login': { component: LoginPage },
	'/login/:status': { component: LoginPage },
	'/pin/:pin': { component: LoginPage },

	'/conversations': { component: ConversationsListPage },
	'/conversation/:id': { component: ConversationPage },

	'/dashboard': { component: DashboardPage },

	'/people': { component: PeopleListPage },
	'/people/:id': { component: PersonPage },

	'/settings': { component: SettingsPage },
};

export default function Routes() {
	return (
		<Switch>
			{Object.keys(RouteDefinitions).map((path) => {
				const { isPrivate, component, exact } = RouteDefinitions[path];
				const Component = isPrivate ? PrivateRoute : Route;
				return (
					<Component
						key={path}
						path={path}
						component={component}
						exact={exact || true}
					/>
				);
			})}
		</Switch>
	);
}
