import React from 'react';
import clsx from 'clsx';
import styles from './PrimaryNav.module.scss';
import { withRouter } from 'react-router-dom';

import history from 'utils/history';
import useWindowOrientation from 'utils/useWindowOrientation';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import ConversationsIcon  from '@material-ui/icons/Forum';
import DashboardIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';

import HubService from '../utils/hub/HubService';

// Export for use elsewhere, like OnBoarding or the LogPage
export const ICONS = {
	ConversationsIcon,
	DashboardIcon,
	SettingsIcon
}

export const NavConfig = [
	{
		id: 'conversations',
		icon: <ConversationsIcon/>,
		url: '/conversations',
		// name: 'Messages'
		position: {
			landscape: 2,
			portrait: 1,
		},
	},
	{
		id: 'dashboard',
		icon: <DashboardIcon/>,
		url: '/dashboard',
		// name: 'People',
		position: {
			landscape: 1,
			portrait: 2,
		},
	},
	{
		id: 'settings',
		icon: <SettingsIcon/>,
		url: '/settings',
		// name: 'Settings',
		position: {
			landscape: 3,
			portrait: 3,
		},
	}
];

NavConfig.forEach((ref, idx ) => {
	const { id } = ref;
	ref.idx = idx;
	NavConfig[id] = ref;
});

/**
 * NavControlService service is envisioned as an importable
 * service other pages can import to change the nav that is highlighted
 * programmatically. (Note: Just changes the indicator, NOT the current page).
 * Also can show/hide the nav (e.g. for onboarding, etc)
 */
export class NavControlService {
	static patchEmpty = patch => NavControlService.EMPTY_STATE_REF.current = {
		...NavControlService.EMPTY_STATE_REF.current,
		...patch
	};

	static EMPTY_STATE_REF = { current: {}, patch: NavControlService.patchEmpty };
	static stateRef = NavControlService.EMPTY_STATE_REF;

	// static NAV_SEARCH    = 0;
	// static NAV_PACKS     = 1;
	// static NAV_PROGRESS  = 2;
	// static NAV_SETTINGS  = 3;

	// static navDestinations = [
	// 	'/search',
	// 	'/home',
	// 	'/progress',
	// 	'/settings',
	// ];

	static showPrimaryNav(visible = true, navItem) {
		// Mark current page for login redir
		HubService.storePageAsLoginRedir();

		this.stateRef.patch({ visible });
		NavControlService.patchEmpty({ visible });

		if (navItem) {
			this.setCurrentNav(navItem);
		}

		const className = 'PrimaryNav-visible';
		if(visible) {
			document.body.classList.add(className);
			document.body.style.setProperty('--primary-nav-safe-area', '4rem');
		} else {
			document.body.classList.remove(className);
			document.body.style.removeProperty('--primary-nav-safe-area');
		}
	}

	static setCompactModeEnabled(compact = true) {
		this.stateRef.patch({ compact });
		NavControlService.patchEmpty({ compact });

		const className = 'PrimaryNav-compact';
		if(compact) {
			document.body.classList.add(className);
			document.body.style.setProperty('--primary-nav-safe-area', '2.25rem');
		} else {
			document.body.classList.remove(className);
			document.body.style.setProperty('--primary-nav-safe-area', '4rem');
		}
	}

	static toggleNavVisible() {
		if(this.isPrimaryNavVisible()) {
			this.showPrimaryNav(false);
		} else {
			this.showPrimaryNav(true);
		}
	}

	static isPrimaryNavVisible() {
		return !!this.stateRef.current.visible;
	}

	static isCompactModeEnabled() {
		return !!this.stateRef.current.compact;
	}

	static getNavDestination(idx) {
		return NavConfig[idx].url;
	}

	static setCurrentNav(navIndex, navigate=false) {
		if (navIndex.idx !== undefined) {
			// eslint-disable-next-line no-param-reassign
			navIndex = navIndex.idx;	
		}

		if(navIndex === undefined
			|| isNaN(navIndex)
			|| navIndex < 0
			|| navIndex >= NavConfig.length
		) {
			console.error(`navIndex given:`, navIndex);
			throw new Error("Invalid navIndex: " + navIndex);
		}
		// console.warn("setCurrentNav:", { navIndex })
		this.stateRef.patch({ navIndex });
		NavControlService.patchEmpty({ navIndex });
		
		if (navigate) {
			history.push(this.getNavDestination(navIndex));
			// I've seen sometimes (e.g. clicking alerts from background)
			// that the scrollTop is partway down the page after loading.
			// This is to fix that
			setTimeout(() => {
				window.scrollTo(0,0);
				document.body.scrollTop = 0;
			}, 250);
		}
	}

	static getCurrentNav() {
		return this.stateRef.current.navIndex || undefined;
	}
}

// Just for debugging
if(process.env.NODE_ENV !== 'production') {
	window.NavControlService = NavControlService;
}

export default withRouter(function PrimaryNav({
	history
}) {
	const [ state, setState ] = React.useState({
		navIndex: NavControlService.getCurrentNav(),
		visible: NavControlService.isPrimaryNavVisible(),
		compact: NavControlService.isCompactModeEnabled(),
	});

	const patchState = (patch = {}) => {
		setState({ ...state, ...patch });
	}

	const resortNavConfig = (key) => {
		if (NavConfig.some(({ position }) => position && position[key])) {
			
			const oldIndexRef = NavConfig[state.navIndex || 0];
			NavConfig.sort((
				{ position: { [key]: a }},
				{ position: { [key]: b }},
			) => a-b);

			const navIndex = NavConfig.indexOf(oldIndexRef);
			patchState({ navIndex });

			NavConfig.forEach((ref, idx ) => {
				const { id } = ref;
				ref.idx = idx;
				NavConfig[id] = ref;
			});
		};
	}

	const orientation = useWindowOrientation();
	
	const bodyLandscapeClassName = 'PrimaryNav-landscape';
	const bodyHasClass = document.body.classList.contains(bodyLandscapeClassName);
	if(orientation.isLandscape) {
		if (!bodyHasClass) {
			document.body.classList.add(bodyLandscapeClassName);
			resortNavConfig('landscape');
		}
	} else {
		if (bodyHasClass) {
			document.body.classList.remove(bodyLandscapeClassName);
			resortNavConfig('portrait');
		}
	}

	React.useEffect(() => {
		const visible = NavControlService.isPrimaryNavVisible();
		const navIndex = NavControlService.getCurrentNav() || 0;
		const compact = NavControlService.isCompactModeEnabled() || false;

		NavControlService.stateRef = {
			current: state,
			patch:   patchState
		}

		if(state.visible !== visible) {
			patchState({ visible });
		}

		if(state.navIndex !== navIndex ) {
			patchState({ navIndex });
			// console.warn("Patching, ", { navIndex, state: state.navIndex });
		} else {
			// console.warn("Not patching, states match", { navIndex, state: state.navIndex });
		}

		if(state.compact !== compact) {
			patchState({ compact });
		}

		return () => {
			NavControlService.stateRef = NavControlService.EMPTY_STATE_REF;
		}
	});

	if(!state || !state.visible) {
		return <></>;
	}

	return (<>
		<BottomNavigation
			value={state.navIndex}
			onChange={(event, navIndex) => {
				NavControlService.setCurrentNav(navIndex);
				const nav = NavControlService.getNavDestination(navIndex);
				if(nav) {
					// ServerStore.metric("app.primary_nav.navigated." + nav.replace('/',''));
					history.push(nav);
				}
			}}
			showLabels
			className={clsx(
				styles.root,
				state.visible && styles.visible,
				state.compact && styles.compact,
				orientation.isLandscape && styles.landscape,
			)}
		>
			{NavConfig.map(({ name, icon, id }) =>
				<BottomNavigationAction key={id} label={name} icon={icon} />
			)}
		</BottomNavigation>
	</>)
});