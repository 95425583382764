/* eslint-disable no-console */
import MessageTypes from 'shared/utils/MessageTypes';
import TimeSyncUtil from 'shared/utils/TimeSyncUtil';

// How frequently should we time-sync with server
const TIME_SYNC_INTERVAL = 1000 * 60; // magic...no reason really...

export default class TimeService {
	constructor(hub) {
		this.hub = hub;

		// Conveniently, addMessageHook returns a sendMessage handler to also send messages
		this.sendMessage = hub.addMessageHook(this, ({ type, ...data }) => {
			switch (type) {
				case MessageTypes.TimeSyncMessage:
					this.timeSync.receivedTimeSync(data);
					break;
				default:
					return false;
			}
			// Unrecognized returns false (default above) so if we got here,
			// we recognized the message, so tell the hub service to stop checking for handlers
			return true;
		});

		this.timeSync = new TimeSyncUtil((msg) => {
			// Uses the utility given above to send messages to the hub
			this.sendMessage(msg);
		});
	}

	currentTime() {
		return this.timeSync.currentTime();
	}

	async start() {
		// const offset = await this.timeSync.primeBestOffset();
		// console.log(
		// 	`Primed server time difference: ${offset} ms`,
		// 	this.timeSync.timeInfo(),
		// );

		this.syncTimer = setInterval(() => {
			if (!this.hub.socket) {
				return;
			}

			this.timeSync.updateBestOffset();
		}, TIME_SYNC_INTERVAL);
	}

	stop() {
		if (this.syncTimer) {
			clearInterval(this.syncTimer);
		}
	}
}
