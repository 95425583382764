const addrs = require('email-addresses');

export default function normalizeEmail(email) {
	if (!email) {
		return {};
	}

	if (typeof email === 'object') {
		if (email.email) {
			const { email: newEmail } = normalizeEmail(email.email);
			// eslint-disable-next-line no-param-reassign
			email.email = newEmail;
		}

		return email;
	}

	const { name, address } = addrs.parseOneAddress(email);
	return { name, email: address };
}
