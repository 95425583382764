import { Avatar, CircularProgress } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import styles from './PersonAvatar.module.scss';

export default function PersonAvatar({ person, inbound = false, className }) {
	const { name, sending } = person || {};
	const initial = `${name || '?'}`.substring(0, 1);
	return (
		<Avatar className={clsx(styles.root, inbound && styles.inbound, className)}>
			{sending ? <CircularProgress /> : initial}
		</Avatar>
	);
}
