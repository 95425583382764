/* eslint-disable no-console, no-shadow */
import React from 'react';
import clsx from 'clsx';
import { CircularProgress, Input } from '@material-ui/core';
import QrReader from 'react-qr-reader';

import history from 'utils/history';
// import useAsyncParam from 'utils/useAsyncParam';
import { useParams } from 'react-router-dom';
import MessageTypes from 'shared/utils/MessageTypes';
import HubService from '../../utils/hub/HubService';

import CustomSkeleton from '../CustomSkelton';
import { SimpleBanner } from '../SimpleBanner/SimpleBanner';
import styles from './LoginPage.module.scss';
import CustomButton from '../CustomButton';
import Head from '../Head';
import { NavControlService } from '../PrimaryNav';

function CustomCard({ active, children, className, ...props }) {
	return (
		<div
			className={clsx(styles.CustomCard, active && styles.visible, className)}
			{...props}
		>
			<div className={styles.internalWrapper}>
				{/* // Only mount if card active so autoFocus
				// doesn't conflict with other inputs that have autoFocus,
				// or for the QR code scanning,
				// if mounted in the background,
				// it will start camera stream for scanning
				// even before user selects QR */}
				{active && children}
			</div>
		</div>
	);
}

// function useParam(params, key) {
// 	return useAsyncParam(params[key], async (val) => ({
// 		[key]: val,
// 	}));
// }

export default function LoginPage() {
	React.useEffect(() => {
		// Has to exec inside an effect, otherwise get React rendering
		// errors about not updating a component while rendering this page
		NavControlService.showPrimaryNav(false);
	});

	const activeState = HubService.useActiveState();
	const { pin: urlPin, status: navStatus } = useParams();

	const setNavStatus = (status) => {
		const newPath = `/login/${status}`;
		if (!window.location.hash.includes(newPath)) {
			history.push(newPath);
		}
	};

	const workspaceList = HubService.useWorkspaceList();
	// console.log('LoginPage got workspace list:', workspaceList);

	const showLoginPage = [
		HubService.ActiveStates.LoginRequired,
		HubService.ActiveStates.LoginError,
		HubService.ActiveStates.VerifyingPin,
		HubService.ActiveStates.WorkspaceRequired,
		HubService.ActiveStates.WorkspaceLoginRequired,
	].includes(activeState);

	// console.log(`LoginPage state debug:`, {
	// 	showLoginPage,
	// 	activeState,
	// 	debug: HubService.latestActiveState,
	// 	debug2: HubService.activeState.value,
	// 	debug3: HubService.activeState.callbacks.slice(),
	// });

	const [loading, setLoading] = React.useState(!showLoginPage);

	const [workspaceName, setWorkspaceName] = React.useState();

	React.useEffect(() => {
		if (activeState === HubService.ActiveStates.Authorized) {
			if (!HubService.gotoLastPreLoginPage()) {
				console.warn(`Going to /dashboard since no pre-login page.`);
				history.push('/dashboard');
			}
		} else if (showLoginPage) {
			setLoading(false);
		}

		if (
			activeState !== HubService.ActiveStates.WorkspaceRequired &&
			(navStatus || '').includes('workspace')
		) {
			setNavStatus('');
		} else if (
			activeState === HubService.ActiveStates.WorkspaceRequired &&
			!(navStatus || '').includes('workspace')
		) {
			const key = workspaceList.length ? 'workspace' : 'new-workspace';
			setNavStatus(key);
			console.warn(`Override workspace screen:`, key);
		}

		HubService.on(LoginPage, HubService.ACTIVE_STATE_CHANGED, (state) => {
			// console.log(`Event caught, activeState change:`, state);

			// Sometimes when window is FRESH loaded to an auth-required URL
			// (ex /camera) and has immediate redirect to /login,
			// for some reason, the state callbacks deep in React get "messed up".
			// E.g. even though the useState in FunctionalState registers
			// our usage, it is stale somehow when the first state change
			// to LoginRequired happens. So this event listener detects the
			// imbalance and forces a new call to the state callbacks to bring
			// it back in sync...can only recreate on fresh loads, weird.
			if (state !== activeState) {
				// Call in a timeout to move out of this callstack because
				// this event handler is called from inside of the setState handler
				setTimeout(() => {
					HubService.activeState.setState(state, { force: true });
				}, 100);
			}

			if (state === HubService.ActiveStates.WorkspaceRequired) {
				const key = workspaceList.length ? 'workspace' : 'new-workspace';
				setNavStatus(key);
			}

			if (state === HubService.ActiveStates.WorkspaceLoginRequired) {
				setWorkspaceName(HubService.pinName);
				setNavStatus('select');
			}

			if (state === HubService.ActiveStates.LoginError) {
				setNavStatus('error');
			}
		});

		return () => HubService.removeAllListeners(LoginPage);
	}, [activeState, navStatus, workspaceList.length, showLoginPage]);

	const [pin, setPinInternal] = React.useState('');

	const validatePin = (pinData = pin) => {
		// Errors will be handled in the navStatus === 'error' card
		HubService.validatePinData(pinData).catch((/* ex */) => {
			// console.warn(`Should have this already:`, ex);
		});
	};

	const setPin = (digits) => {
		if (digits && digits.length >= 6) {
			setTimeout(() => {
				validatePin(digits);
			}, 250);
		}

		setPinInternal(digits);
	};

	const onQrError = (err) => {
		console.error(`QR Module Error:`, err);
	};

	const onQrScan = (data) => {
		// console.log(`Got data:`, data);
		if (data) {
			setPin(data);
		}
	};

	const onAddNewWorkspace = () => {
		setNavStatus('new-workspace');
	};

	// const onLoginFb = () => {
	// 	alert('TODO');
	// };

	const onLoginAnon = () => {
		HubService.authorize({
			type: MessageTypes.AnonymousAuthorizationMessage,
		});
	};

	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [name, setName] = React.useState('');

	const onLoginUserPass = () => {
		HubService.authorize({
			type: MessageTypes.UserPassAuthorizationMessage,
			email,
			pass: password,
		}).catch(() => {});
	};

	const onSignupUserPass = () => {
		HubService.authorize({
			type: MessageTypes.UserPassSignupMessage,
			email,
			pass: password,
		}).catch(() => {});
	};

	const setWorkspace = (workspaceId) => {
		HubService.chooseWorkspaceForUser({ workspaceId });
	};

	const onSaveNewWorkspace = () => {
		HubService.chooseWorkspaceForUser({
			requestType: 'new',
			name,
		});
	};

	const urlPinValidationGuard = React.useRef();
	if (urlPin && urlPinValidationGuard.current !== urlPin) {
		urlPinValidationGuard.current = urlPin;
		setNavStatus('url-pin');
		setPin(pin);
		setTimeout(() => {
			HubService.validatePinData(urlPin).catch(() => {});
		}, 100);
	}

	// console.log(`* On render, navStatus:`, navStatus);

	return (
		<div className={styles.root}>
			{/* <div>{activeState}</div> */}
			<div className={styles.header}>
				<CustomSkeleton active={loading}>
					{workspaceName ? <h1>{workspaceName}</h1> : <SimpleBanner />}
				</CustomSkeleton>
			</div>

			<div className={styles.body}>
				<CustomCard active={!navStatus} className={styles.home}>
					<Head title="ChatBetter" />
					{/* QR Code seems to freeze after scanning on my Google Pixel 3
					- like freezes the entire browser tab, wierd.
					Disableing for now */}
					{/* <CustomButton
						loading={loading}
						className={styles.btnQr}
						onClick={() => setNavStatus('qr')}
					>
						Scan a QR Code
					</CustomButton> */}

					<CustomButton
						loading={loading}
						className={styles.btnPin}
						onClick={() => setNavStatus('pin')}
					>
						Connect with a PIN
					</CustomButton>

					{/* <h3>Login or Sign up</h3> */}
					<CustomButton
						loading={loading}
						className={styles.btnLogin}
						onClick={() => setNavStatus('select')}
					>
						Login
					</CustomButton>

					<CustomButton
						loading={loading}
						className={styles.btnSignup}
						onClick={() => setNavStatus('signup')}
					>
						Sign up
					</CustomButton>
				</CustomCard>

				<CustomCard active={navStatus === 'qr'}>
					<Head title="QR Code - ChatBetter" />
					{activeState === HubService.ActiveStates.VerifyingPin ? (
						<h3>Checking PIN...</h3>
					) : (
						<>
							<h3>
								Scan the ChatBetter QR Code you received to access the Workspace
								or login in
							</h3>

							<QrReader
								delay={300}
								onError={onQrError}
								onScan={onQrScan}
								style={{
									// 414px fills iPhone 6/7/8+
									// while containing it on larger screens
									width: '414px',
									// On screens <414px, this fills the width
									// without overflowing
									maxWidth: '100%',
								}}
							/>
						</>
					)}
				</CustomCard>

				<CustomCard active={navStatus === 'pin'}>
					<Head title="PIN Code - ChatBetter" />
					{activeState === HubService.ActiveStates.VerifyingPin ? (
						<h3>Checking PIN...</h3>
					) : (
						<>
							<h3>Enter PIN code you received for ChatBetter</h3>

							<Input
								label="PIN"
								type="number"
								value={pin || ''}
								autoFocus
								onChange={(evt) => setPin(evt.target.value)}
							/>

							{pin && pin.length >= 6 ? (
								<CustomButton
									className={styles.btnPinVerify}
									onClick={validatePin}
								>
									Verify PIN
								</CustomButton>
							) : (
								''
							)}
						</>
					)}
				</CustomCard>

				<CustomCard active={navStatus === 'url-pin'}>
					<Head title="PIN Code - ChatBetter" />
					<h3>Welcome! Checking PIN...</h3>

					<CircularProgress />
				</CustomCard>

				<CustomCard active={navStatus === 'select'}>
					<Head title="Login - ChatBetter" />
					{workspaceName ? (
						<h3>
							Select how you want to login to &quot;
							<b>{workspaceName}</b>&quot; to get started!
						</h3>
					) : (
						''
					)}
					{/* <CustomButton className={styles.btnFb} onClick={onLoginFb}>
						Continue with Facebook
					</CustomButton> */}

					{/* <CustomButton className={styles.btnAnon} onClick={onLoginAnon}>
						Continue Anonymously
					</CustomButton> */}

					{/* <p>or</p> */}

					<Input
						label="Email"
						type="email"
						// autoFocus
						placeholder="E-mail"
						value={email || ''}
						onChange={(evt) => setEmail(evt.target.value)}
					/>
					<Input
						label="Password"
						type="password"
						placeholder="Password"
						value={password || ''}
						onChange={(evt) => setPassword(evt.target.value)}
					/>

					<CustomButton
						className={styles.btnLoginSubmit}
						onClick={onLoginUserPass}
					>
						Login
					</CustomButton>
				</CustomCard>

				<CustomCard active={navStatus === 'signup'}>
					<Head title="Signup - ChatBetter" />
					<h3>Thanks for trying us out!</h3>

					{/* <CustomButton className={styles.btnFb} onClick={onLoginFb}>
						Continue with Facebook
					</CustomButton> */}

					<CustomButton className={styles.btnAnon} onClick={onLoginAnon}>
						Continue Anonymously
					</CustomButton>

					<p>or</p>

					<Input
						label="Email"
						type="email"
						// autoFocus
						placeholder="E-mail"
						value={email || ''}
						onChange={(evt) => setEmail(evt.target.value)}
					/>
					<Input
						label="Password"
						type="password"
						placeholder="Password"
						value={password || ''}
						onChange={(evt) => setPassword(evt.target.value)}
					/>

					<CustomButton
						className={styles.btnLoginSubmit}
						onClick={onSignupUserPass}
					>
						Create Free Account
					</CustomButton>
				</CustomCard>

				<CustomCard active={navStatus === 'workspace'}>
					<Head title="Choose Workspace - ChatBetter" />
					<h2>Hey {HubService.getUser().name || 'there'}!</h2>

					<h3>Select a workspace to access or create a new workspace below.</h3>

					{workspaceList.map(({ id, name }) => (
						<CustomButton
							className={styles.btnWorkspace}
							key={id}
							onClick={() => setWorkspace(id)}
						>
							{name}
						</CustomButton>
					))}

					<CustomButton
						className={styles.btnNewProj}
						onClick={onAddNewWorkspace}
					>
						Add a new workspace...
					</CustomButton>
				</CustomCard>

				<CustomCard active={navStatus === 'new-workspace'}>
					<Head title="New Workspace - ChatBetter" />
					<h2>New Workspace</h2>

					<h3>Give your workspace a name to get started!</h3>

					<Input
						placeholder="Workspace Name"
						value={name}
						onChange={(evt) => setName(evt.target.value)}
						type="text"
					/>

					{`${name}`.length > 2 && (
						<CustomButton
							className={styles.btnNew}
							onClick={onSaveNewWorkspace}
						>
							Save and Continue
						</CustomButton>
					)}
				</CustomCard>

				<CustomCard active={navStatus === 'error'}>
					{HubService.loginError &&
					HubService.loginError.errorCode === 'validation-error' ? (
						<>
							<Head title="Login Trouble - ChatBetter" />
							<h2>Terribly Sorry</h2>
							<h3>{HubService.loginError.error}</h3>
						</>
					) : (
						<>
							<Head title="Error - ChatBetter" />
							<h2>Trouble Logging In</h2>

							<h3>
								Terribly sorry, there seem to be some issues logging in.
								{HubService.loginError
									? ' Technical details below.'
									: ' Please go back and try again.'}
							</h3>

							{HubService.loginError ? (
								<code>
									{HubService.loginError.error ||
										HubService.loginError.message ||
										JSON.stringify(HubService.loginError)}
								</code>
							) : (
								''
							)}
						</>
					)}

					<CustomButton onClick={() => history.push('/login')}>
						Back to Login
					</CustomButton>
				</CustomCard>
			</div>
		</div>
	);
}
