/* eslint-disable no-console, no-nested-ternary, no-multi-assign, no-return-assign */
import { EventEmitter } from 'events';
import WebSocketMessageWrapper from 'shared/utils/WebSocketMessageWrapper';
import { server } from './ServerUtil';
import HubService from './hub/HubService'; // eslint-disable-line import/no-cycle

// Only set to true for development
// const SERVERLESS_TESTING = true; // (process.env.NODE_ENV === 'production') ? false : true;
export class ServerStore {
	static _events = new EventEmitter();

	static on(event, callback) {
		this._events.on(event, callback);
	}

	static off(event, callback) {
		this._events.off(event, callback);
	}

	static emit(event, data) {
		this._events.emit(event, data);
	}

	static server() {
		return server;
	}

	// Random delay used to simulate network congestion / slow server response times
	static async randomTestingDelay() {
		// Never leave this on in production again...
		if (
			process.env.NODE_ENV === 'production' ||
			process.env.REACT_APP_STAGING === 'true'
		) {
			return;
		}

		// Only delay in testing
		await new Promise((resolve) =>
			setTimeout(() => resolve(), 500 * Math.random() + 650),
		);
	}

	static async _wrap(
		method,
		endpoint,
		data = null,
		{ autoRetry = true, retryCount = 0 } = {},
	) {
		await HubService.waitForToken();

		if (!server.token) {
			console.error(`No token set, cannot make a request to:`, {
				method,
				endpoint,
				data,
			});
			throw new Error(`Server Utility has no token, request will fail`);
		}

		return WebSocketMessageWrapper(HubService, method, endpoint, data, {
			autoRetry,
			retryCount,
		});
	}

	static _get = (endpoint, data = {}, opts = { autoRetry: true }) =>
		this._wrap(
			'get',
			endpoint,
			data || {},
			// Enable autoRetry by default on all GET requests
			{ autoRetry: true, ...(opts || {}) },
		);

	static _post = (endpoint, ...args) => this._wrap('post', endpoint, ...args);

	static _delete = (endpoint, ...args) =>
		this._wrap('delete', endpoint, ...args);

	// static async GetWorkspace(workspaceId) {
	// 	return this._get('workspace', { workspaceId });
	// }

	// static async GetMessageMeta(messageId) {
	// 	return this._get('message-meta', { messageId });
	// }

	static async GetConversationsList() {
		return this._get('conversations');
	}

	static async GetConversation(
		conversationId,
		{
			// numMessages is number of initial messages to retrieve
			numMessages = 100,
			// Additional messages can be retrieved by providing the epoch in messagesBeforeEpoch which will load messages older than that epoch (in numMessages blocks)
			// You can get the epoch from messages[0].epoch
			messagesBeforeEpoch = null,
		} = {},
	) {
		return this._get('conversation', {
			conversationId,
			numMessages,
			messagesBeforeEpoch,
		});
	}

	// static async UpdateConversation(props) {
	// 	return this._post('conversation', props);
	// }

	static async UpdateConversationUser(props) {
		return this._post('conversation-user', props);
	}

	static async SendMessage(props) {
		return this._post('message', props);
	}

	// static async UpdateMessageMeta(rec, patch) {
	// 	return this._post('message-meta', { messageId: rec.id, ...patch });
	// }

	static async UpdateUserProps(patch) {
		return this._post('user', patch);
	}

	static async UpdateWorkspaceMeta(patch) {
		return this._post('workspace', patch);
	}

	static async RemoveAttachment(attachmentId) {
		return this._post('remove-attachment', { attachmentId });
	}

	static async DraftAttachments(conversationId) {
		return this._get('draft-attachments', { conversationId });
	}

	static async GetPeople(query) {
		return this._get('people', query);
	}

	static async GetPerson(personId) {
		return this._get('person', { personId });
	}

	static async UpdatePerson(personId, patch) {
		return this._post('person', { personId, ...patch });
	}

	static async GetConversationPerson({ name, phoneNum, email }) {
		return this._post('people', { name, phoneNum, email });
	}

	static async FindLatestConversation(personId) {
		return this._post('find-latest-conversation', { personId });
	}
}

window.store = ServerStore;
