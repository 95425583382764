/* eslint-disable no-console */
import React, { useEffect } from 'react';
import StaticEvents from 'shared/utils/StaticEvents';
import MessageTypes from 'shared/utils/MessageTypes';
import SocketClient from 'shared/utils/SocketClient';
import { promiseMap } from 'shared/utils/promise-map';
import { stableDefer } from 'shared/utils/defer';
import DeviceInfo from 'shared/utils/DeviceInfo';
import FunctionalState from 'shared/components/FunctionalState';
import AppConfig from 'shared/config';
import history from '../history';
import AuthService from './AuthService';
import TimeService from './TimeService';
import { ServerStore } from '../ServerStore'; // eslint-disable-line import/no-cycle

// magic ...fast enough to catch it, long enough for slow devices
const HTTPS_WARNING_TIME = 2500;

const WORKSPACE_TOKEN_KEY = '@chatbetter/workspace-token';

const LOGIN_REDIR_KEY = '@chatbetter/login-redir';
export default class HubService extends StaticEvents {
	static ActiveStates = {
		Disconnected: 'Disconnected',
		Connecting: 'Connecting',
		LoginRequired: 'LoginRequired',
		VerifyingPin: 'VerifyingPin',
		WorkspaceLoginRequired: 'WorkspaceLoginRequired',
		Authorizing: 'Authorizing',
		WorkspaceRequired: 'WorkspaceRequired',
		SettingWorkspace: 'SettingWorkspace',
		Authorized: 'Authorized',
		LoginError: 'LoginError',
	};

	static ACTIVE_STATE_CHANGED = 'ACTIVE_STATE_CHANGED';

	static activeState = new FunctionalState(
		this.ActiveStates.Disconnected,
		(value) => {
			// console.warn(`* New ActiveState for HubService:`, value);
			this.emit(this.ACTIVE_STATE_CHANGED, value);

			HubService.latestActiveState = value;
		},
	);

	static workspaceState = new FunctionalState({});

	static workspaceListState = new FunctionalState([]);

	static messageHooks = [];

	// Handles time sync with hub
	static timeService = new TimeService(HubService);

	// Manages authorization-related logic and provides auth hooks for React
	static authService = new AuthService(HubService);

	static waitForTokenPromise = stableDefer({
		timeout: -1, // disable
	});

	static waitForReadyPromise = stableDefer({
		autoStart: false,
		timeoutErrorMessage: 'waitForReadyPromise timeout',
		callback: () => {
			if (!this.started) {
				setTimeout(() => {
					this.connect();
				}, 100);
			}
		},
	});

	static waitForToken() {
		return this.waitForTokenPromise.getValue();
	}

	static waitForReady() {
		return this.waitForReadyPromise.getValue();
	}

	static useAuthRequired() {
		const activeState = this.useActiveState();

		const showLoginPage = [
			HubService.ActiveStates.LoginRequired,
			HubService.ActiveStates.LoginError,
			HubService.ActiveStates.WorkspaceRequired,
			HubService.ActiveStates.WorkspaceLoginRequired,
		].includes(activeState);

		const connectingFlag = activeState === HubService.ActiveStates.Connecting;
		const needsConnected = activeState === HubService.ActiveStates.Disconnected;
		const authFlag = activeState === HubService.ActiveStates.Authorized;
		const [isAuthorized, setIsAuthorized] = React.useState(
			connectingFlag || needsConnected ? undefined : authFlag,
		);

		// console.log(`useAuthRequired hook:`, {
		// 	isAuthorized,
		// 	authFlag,
		// 	activeState,
		// });

		React.useEffect(() => {
			if (needsConnected) {
				if (!this.started) {
					HubService.connect();
				}
			} else if (showLoginPage) {
				history.push('/login');
			} else if (authFlag && !isAuthorized) {
				setIsAuthorized(true);
			}
		}, [showLoginPage, authFlag, isAuthorized, needsConnected]);

		return isAuthorized;
	}

	static useActiveState() {
		return this.activeState.useState();
	}

	static getActiveState() {
		return this.activeState.getValue();
	}

	static connectPromise = stableDefer({
		callback: () => {
			if (!this.started) {
				this.connect();
			}
		},
		autoStart: false,
		timeoutErrorMessage: `Connect timeout`,
	});

	static waitForConnect() {
		return this.connectPromise.getValue();
	}

	static getWorkspaceId() {
		const { id } = this.workspaceState.getValue() || {};
		return id;
	}

	static async connect() {
		if (this.started) {
			return;
		}

		this.started = true;

		// Called when socket error happens, if given
		this.onConnectionError = null;

		// console.warn(`HubService: Starting base services ...`);

		// Connect to the hub
		this.createSocket();

		// Just for debugging
		window.HubService = this;
	}

	static getSSLPage() {
		let ip = AppConfig.apiHost;
		const { protocol, host } = window.location;

		if (ip.includes(`localhost:${AppConfig.tcpPort}`)) {
			ip = `${window.location.hostname}:${AppConfig.tcpPort}`;
		}

		// Add pin into redirect only if user has already tried calling
		// .validatePinData ...
		const { latestPinData: pin } = this;
		const pinRedirect = !pin
			? ''
			: `#/pin/${encodeURI(
					pin.startsWith('http')
						? pin.replace(/^https?:\/\/.*pin\/(\d+)/, '$1')
						: pin,
			  )}`;

		const returnUrl = `${protocol}//${host}${pinRedirect}`;
		return `https://${ip}/ssl.html#${returnUrl}`;
	}

	static createSocket() {
		const { onConnectionError } = this;

		let ip = AppConfig.apiHost;
		if (ip.includes(`localhost:${AppConfig.tcpPort}`)) {
			ip = `${window.location.hostname}:${AppConfig.tcpPort}`;
		}

		let startTime;

		// Prod uses nginx frontend to handle SSL, dev doesn't
		const proto = AppConfig.buildEnv === 'prod' ? 'wss' : 'ws';
		this.socket = new SocketClient(`${proto}://${ip}/app`);
		this.socket.on(SocketClient.CONNECTING, () => {
			console.log(`BaseHubService: Connecting to ${ip} ...`);

			this.isConnected = false;
			this.isReady = false;
			this.loginReturnUrl = null;

			startTime = Date.now();
			if (!this.connectCount) {
				this.connectCount = 0;
			}

			this.connectCount += 1;

			this.activeState.setState(this.ActiveStates.Connecting);
		});
		this.socket.on(SocketClient.MESSAGE, this.onSocketMessage);
		this.socket.on(SocketClient.CONNECTED, this.onSocketConnected);
		this.socket.on(SocketClient.CLOSED, (ex) => {
			const timeDiff = Date.now() - startTime;
			const code = timeDiff < HTTPS_WARNING_TIME ? 'SSL' : 'UNKNOWN';

			this.activeState.setState(this.ActiveStates.Disconnected);

			if (code === 'SSL') {
				if (this.connectCount > 7) {
					this.socket.cancelReconnect();

					console.warn(
						`SSL Certificate for hub unrecognized. Visit ${this.getSSLPage()} to accept`,
						{ timeDiff },
					);

					history.push('/ssl-reroute');
				} else {
					console.warn(
						`Quick disconnect, might be SSL error, going to allow a retry then error out`,
					);
				}
			} else if (onConnectionError) {
				onConnectionError({
					code,
					ip,
					message:
						code === 'SSL'
							? 'SSL Certificate Needs Accepted'
							: `Cannot connect to Hub at ${ip}`,
				});
			} else {
				console.warn(`Encountered closed socket for unknown reason:`, ex, {
					HTTPS_WARNING_TIME,
					timeDiff,
				});
				// history.push('/');
			}
		});
	}

	static disconnect() {
		if (!this.started) {
			return;
		}

		console.warn(`HubService stopping ...`);
		this.started = false;

		this.activeState.setState(this.ActiveStates.Disconnected);

		if (this.timeService) {
			this.timeService.stop();
		}

		if (this.socket) {
			this.socket.disableReconnect();
			this.socket.close();
			this.socket.removeAllListeners();
			this.socket = null;
		}

		console.log(`HubService stopped`);
	}

	static onSocketConnected = async () => {
		// Reset for SSL detection above
		this.connectCount = 0;

		// console.log(`HubService: Socket Connected ...`);

		// Sync a local clock instance with the hub's clock for video event sync
		// await this.timeService.start();

		// console.log(`HubService: Time online`);

		this.isConnected = true;
		this.connectPromise.resolve(true);

		// If user has logged in before (stored token)
		// then no need to flag "LoginRequired".
		// Conversely, if no login token, user needs to choose how to authenticate again
		if (!this.reauthorize()) {
			this.setLoginRequired();
		}
	};

	static hasStoredToken() {
		return this.authService.hasStoredToken();
	}

	static reauthorize() {
		if (!this.hasStoredToken()) {
			return false;
		}

		return this.authorize({
			type: MessageTypes.JWTAuthorizationMessage,
		});
	}

	static setStoredWorkspaceToken(token) {
		return window.localStorage.setItem(WORKSPACE_TOKEN_KEY, token);
	}

	static getStoredWorkspaceToken() {
		return window.localStorage.getItem(WORKSPACE_TOKEN_KEY);
	}

	static removeStoredWorkspaceToken() {
		window.localStorage.removeItem(WORKSPACE_TOKEN_KEY);
	}

	static async authorize(authData) {
		if (!authData || !authData.type) {
			throw new Error(`Invalid authData`);
		}

		const storedToken = this.getStoredWorkspaceToken();

		/* eslint-disable no-param-reassign */
		const { pinToken, pinTokenType } = this;
		if (pinToken && pinTokenType === 'workspace') {
			authData.workspaceToken = pinToken;
		} else if (storedToken) {
			// If we have a stored (previously selected) workspace token,
			// send it along with the auth data.
			// If workspaceToken is present (and valid), the server
			// will automatically authorize the socket to the workspace
			// and proactively send us a SetWorkspaceForUserMessage
			// even without us having to set the workspace
			authData.workspaceToken = storedToken;
		}
		/* eslint-enable no-param-reassign */

		this.activeState.setState(this.ActiveStates.Authorizing);

		// TODO: Provide user creds
		let error;
		this.loginError = null;
		const result = await this.authService
			.authorizeSocket(authData)
			.catch((ex) => {
				error = ex;
			});

		if (result instanceof Error) {
			error = result;
		}

		if (error) {
			if (storedToken) {
				console.warn(`Stored token didn't work, requiring login...`);
				this.setLoginRequired({ forceRedirect: true });
				return;
			}

			console.error(`Caught error authorizing (${error.errorCode}):`, error);
			// if (this.readyPromise) {
			// 	this.readyPromise.reject(error);
			// 	clearTimeout(this.readyDeadmanTimer);
			// }
			// this.waitForReadyPromise.reject(error);

			this.loginError = error;

			this.activeState.setState(this.ActiveStates.LoginError);

			this.setLoginRequired();

			return;
		}

		this.waitForTokenPromise.resolve(true);
		this.initWorkspacesForUser();
	}

	static async initWorkspacesForUser() {
		const list = await this.getWorkspacesForUser();
		this.workspaceListState.setState(list);

		if (!this.getWorkspaceId()) {
			// WorkspaceID could already be set if we had a stored workspace token
			// that we sent as part of the auth request, because prior to responding
			// with an auth message success, the server will proactively send
			// a SetWorkspaceForUserMessage message which would set the workspaceId
			// before we get to this spot in the code, so no need to do the
			// choose flow

			console.log(`* got list of workspaces for user:`, list);
			if (list.length === 1) {
				await this.chooseWorkspaceForUser({
					workspaceId: list[0].id,
				});
			} else {
				this.activeState.setState(this.ActiveStates.WorkspaceRequired);
				this.tryRedirToLoginPage({ path: `/workspace` });
			}
		}
	}

	static bootCompleted() {
		this.activeState.setState(this.ActiveStates.Authorized);
		this.isReady = true;
		this.waitForReadyPromise.resolve(true);
		this.gotoLastPreLoginPage();

		// Sometimes we've found we don't get a time, so force sync
		// this.timeService.timeSync.primeBestOffset();
	}

	static addMessageHook(classRef, callback) {
		if (classRef && callback) {
			this.messageHooks.push({ classRef, callback });
		}
		return (message) => this.sendMessage(message);
	}

	static removeMessageHooks(classRef) {
		this.messageHooks = this.messageHooks.filter(
			(h) => h.classRef !== classRef,
		);
	}

	static onSocketMessage = async (data) => {
		const { type } = data;

		// if (type !== MessageTypes.TimeSyncMessage) {
		// 	console.warn(`<< IN << `, type);
		// }

		let consumed;
		await promiseMap(this.messageHooks, async ({ callback }) => {
			if (!consumed && (await callback(data))) {
				consumed = true;
			}
		});

		if (consumed) {
			return;
		}

		switch (type) {
			case MessageTypes.PingMessage:
				this.sendMessage({ type: MessageTypes.PongMessage });
				break;
			case MessageTypes.PINValidationMessage:
				this.handleVerifyPinResponse(data);
				break;
			case MessageTypes.GetWorkspacesForUserMessage:
				if (this.getWorkspacesPromise) {
					const { workspaces } = data;
					this.getWorkspacesPromise.resolve(workspaces);
					this.getWorkspacesPromise = null;
				} else {
					console.warn(`No pending getWorkspaces promise`);
				}
				break;
			case MessageTypes.SetWorkspaceForUserMessage:
				this.processSetWorkspaceResponse(data);
				break;
			case MessageTypes.DebugSocketMessage:
				// IGNORE
				break;
			default:
				if (process.env.NODE_ENV !== 'production') {
					console.warn(`Unknown message type from server:`, data);
				}
				break;
		}
	};

	static async validatePinData(pinData) {
		this.latestPinData = pinData;
		this.activeState.setState(this.ActiveStates.VerifyingPin);
		const deviceInfo = await DeviceInfo.getDeviceInfo();
		this.pinValidationPromise = stableDefer({
			timeout: 1000 * 30,
			timeoutErrorMessage: 'validatePinData timeout',
		});
		this.sendMessage({
			type: MessageTypes.PINValidationMessage,
			pinData,
			deviceInfo,
		});

		return this.pinValidationPromise;
	}

	static handleVerifyPinResponse({
		token,
		tokenType,
		name,
		imageUrl,
		...response
	}) {
		if (!token || !tokenType) {
			this.loginError = response;
			this.activeState.setState(this.ActiveStates.LoginError);
			if (this.pinValidationPromise) {
				this.pinValidationPromise.resolve(
					new Error(response.error || 'Invalid PIN verification response'),
				);
				this.pinValidationPromise = null;
			}

			if (!response.error) {
				console.error(`Unknown PIN verification response:`, response);
			}
			return;
		}

		this.pinToken = token;
		this.pinTokenType = tokenType;
		this.pinName = name;
		this.pinImageUrl = imageUrl;

		// TODO
		console.warn(`Got good pin verification:`, { token, tokenType });

		if (tokenType === 'workspace') {
			// Workspace token, so ask user how to authorize - basically, same as
			// the "login" button in the Login page
			this.activeState.setState(this.ActiveStates.WorkspaceLoginRequired);
		} else {
			// User token, so authorize socket immediately with the token
			this.authorize({
				type: MessageTypes.JWTAuthorizationMessage,
				token,
			});
		}

		if (this.pinValidationPromise) {
			this.pinValidationPromise.resolve({ token, tokenType });
			this.pinValidationPromise = null;
		} else {
			console.warn(`No pending pinValidationPromise?`);
		}
	}

	static getUser() {
		return (this.authService.authorization || {}).user || {};
	}

	static getUserDisplay() {
		const { email, name } = this.getUser();
		return email || name;
	}

	static getWorkspacesForUser() {
		this.getWorkspacesPromise = stableDefer('getWorkspacesPromise timeout');
		// console.warn(`getWorkspacesForUser: sending GetWorkspacesForUserMessage`);

		this.sendMessage({
			type: MessageTypes.GetWorkspacesForUserMessage,
		});
		return this.getWorkspacesPromise;
	}

	static chooseWorkspaceForUser({
		// if type 'new', provide 'name' and no 'workspaceId
		requestType = 'existing',
		workspaceId = '',
		name = null,
	}) {
		this.setWorkspacePromise = stableDefer('setWorkspacePromise timeout');
		this.sendMessage({
			type: MessageTypes.SetWorkspaceForUserMessage,
			requestType,
			workspaceId,
			name,
		});
		if (requestType === 'new') {
			// Need to flag this so the list gets re-downloaded
			// when response received. This is used for when the user
			// uses settings page to go to "new workspace" screen so that the
			// new workspace they create gets added to the list
			this.updateListOnResponse = true;
		}
		return this.setWorkspacePromise; // .getValue();
	}

	static async processSetWorkspaceResponse(data) {
		// console.warn(`* got SetWorkspaceForUserMessage response:`, data);

		/* TODO */
		// Actually make use of the roles declared in workspaceUser
		/* TODO */
		// eslint-disable-next-line no-unused-vars
		const { workspace, workspaceUser, workspaceToken, result } = data;

		if (!result.success) {
			if (this.setWorkspacePromise) {
				this.setWorkspacePromise.reject(new Error(JSON.stringify(result)));
				this.setWorkspacePromise = null;
			}

			this.setWorkspace(null);

			this.activeState.setState(this.ActiveStates.LoginError);

			return;
		}

		// Store the select workspace so when users returns, they can
		// go right to this workspace if the app automatically auths
		this.setStoredWorkspaceToken(workspaceToken);

		this.setWorkspace(workspace);

		if (this.setWorkspacePromise) {
			this.setWorkspacePromise.resolve(data);
			this.setWorkspacePromise = null;
		}

		if (this.updateListOnResponse) {
			this.updateListOnResponse = false;

			const list = await this.getWorkspacesForUser();
			this.workspaceListState.setState(list);
		}

		this.bootCompleted();
	}

	static useWorkspaceList() {
		return Array.from(this.workspaceListState.useState() || []);
	}

	static useWorkspace() {
		return this.workspaceState.useState();
	}

	static setWorkspace(workspace) {
		this.workspaceState.setState(workspace);
	}

	static async updateWorkspaceMeta(meta) {
		const workspace = await ServerStore.UpdateWorkspaceMeta(meta);
		this.setWorkspace(workspace);

		const list = await this.getWorkspacesForUser();
		this.workspaceListState.setState(list);
	}

	static sendMessage(message) {
		if (this.socket) {
			// const { type } = message;
			// if (type !== MessageTypes.TimeSyncMessage) {
			// 	console.warn(`>> OUT >> `, message.type);
			// }

			this.socket.send(message);
		} else {
			console.warn(`No socket connected, cannot send message:`, message);
		}
	}

	static getSocket() {
		return this.socket;
	}

	static useAuthorization() {
		if (!this.started) {
			this.connect();
		}

		return this.authService.useAuthorization();
	}

	static getCurrentTime() {
		return this.timeService.currentTime();
	}

	static logout() {
		this.removeStoredWorkspaceToken();
		this.authService.logout();
		this.pinToken = null;
		this.pinTokenType = null;
		this.setWorkspace(null);
		this.workspaceListState.setState([]);
		window.localStorage.removeItem(LOGIN_REDIR_KEY);
		console.log(`Logout, redirecting to login page...`);
		this.setLoginRequired({ forceRedirect: true });
	}

	static setLoginRequired({ forceRedirect = false } = {}) {
		this.activeState.setState(this.ActiveStates.LoginRequired);

		this.tryRedirToLoginPage({ forceRedirect });
	}

	static tryRedirToLoginPage({ forceRedirect = false, path = '' } = {}) {
		const currentPage = window.location.hash.substring(1);
		if (forceRedirect || !currentPage.startsWith('/login')) {
			this.storePageAsLoginRedir(currentPage);
			history.push(`/login${path}`);
		}
	}

	static storePageAsLoginRedir(page) {
		const currentPage = page || window.location.hash.substring(1);
		// console.log(`storePageAsLoginRedir currentPage:`, currentPage);
		if (!currentPage.includes('/login') && !currentPage.includes('/signup')) {
			window.localStorage.setItem(LOGIN_REDIR_KEY, currentPage);
		}
	}

	static gotoLastPreLoginPage() {
		const loginRedir = window.localStorage.getItem(LOGIN_REDIR_KEY);
		if (
			loginRedir &&
			!loginRedir.includes('/ssl-reroute') &&
			!loginRedir.includes('/login') &&
			loginRedir !== '/'
		) {
			history.push(loginRedir);
			console.warn(`gotoLastPreLoginPage requested, pushing `, loginRedir);
			return true;
		}

		if (loginRedir) {
			window.localStorage.removeItem(LOGIN_REDIR_KEY);
		}

		// console.error(
		// 	`gotoLastPreLoginPage requested, but no page in localStorage`,
		// );

		return false;
	}
}

export function useMessageHook(callback) {
	useEffect(() => {
		const classRef = Math.random();
		HubService.addMessageHook(classRef, callback);
		return () => HubService.removeMessageHooks(classRef);
	});
}
