import React, { useState, useEffect } from 'react';
import normalizeEmail from 'shared/utils/normalizeEmail';
import normalizePhone from 'shared/utils/normalizePhone';
import { ServerStore } from 'utils/ServerStore';
import {
	List,
	ListItem,
	Tooltip,
	Typography,
	IconButton,
} from '@material-ui/core';
import formatPhone from 'shared/utils/formatPhone';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styles from './PersonPage.module.scss';
import { NavConfig, NavControlService } from '../PrimaryNav';
import HeaderWidget from '../HeaderWidget';
import useAsyncParam from '../../utils/useAsyncParam';
import CustomSkeleton from '../CustomSkelton';
import ValueEditDialog from '../ValueEditDialog';
import HubService from '../../utils/hub/HubService';
import ListItemLink from '../ListItemLink';
import SimpleBadge from '../SimpleBadge';

export default function PersonPage({ match: { params } }) {
	useEffect(() => {
		// Has to exec inside an effect, otherwise get React rendering
		// errors about not updating a component while rendering this page
		NavControlService.showPrimaryNav(true, NavConfig.people);
	});

	// If still loading connection, returns undefined.
	// If loaded but LoginRequired, redirects to /login (leaves this page)
	// If loaded and authorized, returns true
	HubService.useAuthRequired();

	const { id: personId } = params;
	const { person, conversation, loadDone, error, reloadData } =
		useAsyncParam(personId, (id) => ServerStore.GetPerson(id)) || {};

	const { name, email: rawEmail, phoneNum: rawPhone } = person || {};

	const { numNewMessages } = conversation || {};
	const hasNewMessages = parseInt(numNewMessages, 10) > 0;

	const { email } = normalizeEmail(rawEmail);
	const phoneNum = normalizePhone(rawPhone);

	const [otherLoading, setOtherLoading] = useState();
	const loading = otherLoading || !loadDone;

	const [editOpen, setEditOpen] = useState();
	const [editOptions, setEditOptions] = useState({});

	const onEditSave = (patch) => {
		setOtherLoading(true);
		ServerStore.UpdatePerson(personId, patch).then(() => {
			reloadData();
			setOtherLoading(false);
		});
		setEditOpen(false);
	};

	const onEditCancel = () => setEditOpen(false);
	const settingsPrompt = (field) => {
		const fieldOptions = {
			name: {
				label: 'Name',
				type: 'text',
				value: name,
				field: 'name',
			},
			email: {
				label: 'E-mail',
				type: 'email',
				value: email,
				field: 'email',
			},
			phoneNum: {
				label: 'Phone Number',
				type: 'tel',
				value: phoneNum,
				field: 'phoneNum',
			},
		};

		setEditOptions(fieldOptions[field]);
		setEditOpen(true);
	};

	return (
		<>
			<HeaderWidget title={error ? 'Error' : name} loading={loading} />

			<ValueEditDialog
				options={editOptions}
				open={editOpen}
				onSave={onEditSave}
				onCancel={onEditCancel}
			/>

			<div className={styles.root}>
				{error ? (
					<>
						<h1>Trouble Loading Person {personId}</h1>
						<code>{error.message}</code>
					</>
				) : (
					''
				)}

				<List className={styles.fieldList} dense>
					<Tooltip title="Change the name of the person">
						<ListItem
							button
							className={styles.myListItem}
							onClick={() => settingsPrompt('name')}
						>
							<Typography variant="body2">Name</Typography>
							<CustomSkeleton active={loading}>
								<Typography align="right" className={styles.value}>
									{loading ? 'John Doe' : name}
								</Typography>
							</CustomSkeleton>
						</ListItem>
					</Tooltip>
					<Tooltip title="Change the email of the person">
						<ListItem
							button
							className={styles.myListItem}
							onClick={() => settingsPrompt('email')}
						>
							<Typography variant="body2">Email</Typography>
							<CustomSkeleton active={loading}>
								<Typography align="right" className={styles.value}>
									{loading ? 'test@email.com' : email}
								</Typography>
							</CustomSkeleton>
						</ListItem>
					</Tooltip>
					<Tooltip title="Change the phone number of the person">
						<ListItem
							button
							className={styles.myListItem}
							onClick={() => settingsPrompt('phoneNum')}
						>
							<Typography variant="body2">Phone</Typography>
							<CustomSkeleton active={loading}>
								<Typography align="right" className={styles.value}>
									{formatPhone(loading ? '+1000000000' : phoneNum)}
								</Typography>
							</CustomSkeleton>
						</ListItem>
					</Tooltip>

					<Tooltip title="View latest conversation">
						<ListItemLink
							href={`#/conversation/${(conversation || {}).id}`}
							className={styles.myListItem}
						>
							<Typography variant="body2">
								Go to Messages
								{hasNewMessages ? (
									<SimpleBadge>{numNewMessages} unread</SimpleBadge>
								) : (
									''
								)}
							</Typography>
							<CustomSkeleton active={loading}>
								<div className={styles.endButtonWithLabel}>
									{/* <Typography align="right" className={styles.value}>
										{numPeople} contacts
									</Typography> */}
									<IconButton>
										<ChevronRightIcon />
									</IconButton>
								</div>
							</CustomSkeleton>
						</ListItemLink>
					</Tooltip>
				</List>
			</div>
		</>
	);
}
