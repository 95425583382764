import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
// import StatsOverlay from 'shared/components/StatsOverlay';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';
import { mobileDetect } from 'utils/mobile-detect';
import { HashRouter as Router, withRouter } from 'react-router-dom';
import history from 'utils/history';
import Routes from 'components/Routes';
import PrimaryNav from './components/PrimaryNav';
import HubService from './utils/hub/HubService';

// StatsOverlay.init();
// StatsOverlay.hide();

// Init connection to the hub as soon as app boots
HubService.connect();

const HistoryGrabber = withRouter(({ history: ref }) => {
	history.ref = ref;
	return <></>;
});

const NotchHelper = () => {
	React.useEffect(() => {
		if (mobileDetect.hasNotch) {
			document.body.classList.add('notched');
		} else {
			document.body.classList.remove('notched');
		}

		if (window.isPhoneGap) {
			document.body.classList.add('native');
		}

		// // Just for testing
		if (process.env.NODE_ENV !== 'production') {
			// document.body.classList.add('notched');
			// document.body.classList.add('native');
		}

		return () => document.body.classList.remove('notched');
	});

	return <></>;
};

function useRootHelper() {
	return React.useEffect(() => {
		const root = document.getElementById('root');

		const resize = () => {
			root.style.height = `${window.innerHeight}px`;
		};
		resize();
		window.addEventListener('resize', resize);

		return () => window.removeEventListener('resize', resize);
	});
}

export default function App() {
	setStatusBarColor('#000000'); // set to black because bar text is often white

	useRootHelper();

	// Render our app
	return (
		<>
			<Router>
				<CssBaseline />
				<NotchHelper />
				<HistoryGrabber />
				<Routes />
				<PrimaryNav />
			</Router>
		</>
	);
}
