/* eslint-disable no-nested-ternary, no-shadow */
import React, { useEffect } from 'react';
import clsx from 'clsx';

import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import { ServerStore } from 'utils/ServerStore';
import { defer } from 'shared/utils/defer';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import {
	Input,
	List,
	ListItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	CircularProgress,
} from '@material-ui/core';
import styles from './AddConvoDialog.module.scss';

class PersonQuery {
	static setLoadingCallback(cb) {
		this.loadingCallback = cb;
	}

	static stop() {
		this.stopped = true;
		if (this.loadingCallback) {
			this.loadingCallback(false);
		}
	}

	static async _search() {
		const query = {
			search: this.searchString,
		};

		// console.log("[_search] query is:", query);

		const { list } = await ServerStore.GetPeople(query);

		// console.log("Serach got list: ", list);

		if (this.searchString !== query.search) {
			// console.warn("Search changed while waiting for results, not resolving");
			return;
		}

		if (this.stopped) {
			// console.warn("Stopped while waiting for results, not resolving");
			return;
		}

		if (this.loadingCallback) {
			this.loadingCallback(false);
		}

		// console.log('For query ', this.searchString, ', got:', list);

		// ServerStore.metric('app.choose_person.searched', null, {
		// 	query: query.searchString,
		// 	numResults: list.length,
		// });

		if (this.searchPromise) {
			this.searchPromise.resolve(list);
			this.searchPromise = null;
		}
	}

	static async search(string = '') {
		if (this.loadingCallback) {
			this.loadingCallback(true);
		}

		this.stopped = false;
		this.searchString = string;

		if (!this.searchPromise) {
			this.searchPromise = defer();
		}

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._search();
		}, this.delay || 500);

		return this.searchPromise;
	}

	static stopTimers() {
		clearTimeout(this.searchTimer);
	}
}

export default function AddConvoDialog({ open, onCancel, onSave }) {
	const [results, setResults] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [activeRow, setActiveRow] = React.useState(null);
	const listRef = React.useRef();

	useEffect(() => () => PersonQuery.stopTimers());

	const onCloseDialog = () => {
		PersonQuery.searchString = '';
		if (onCancel) {
			onCancel();
		}
	};

	const choosePerson = async (person) => {
		if (onSave) {
			setLoading(true);

			if (!person) {
				// enter pressed with no results, so use the GetConversationPerson() endpoint
				const text = `${PersonQuery.searchString}`;
				let email = null;
				let phoneNum = null;
				let name = null;

				// Make VERY basic guesses at what is in 'text'
				if (text.includes('@')) {
					email = text;
				} else if (/\d{3+}/.test(text)) {
					phoneNum = text;
				} else {
					name = text;
				}

				const props = {
					email,
					phoneNum,
					name,
				};

				// console.log(`deduced props:`, props);

				// Ask the server to find/create a person and conversation based on
				// our deduced values
				const {
					person,
					conversation,
				} = await ServerStore.GetConversationPerson(props);

				// console.log(`Based on text '${text}', found:`, {
				// 	props,
				// 	person,
				// 	conversation,
				// });

				onSave({ person, conversation, isNew: true });
			} else {
				// Ask server to find or create a conversation for the given person
				// (creating a convo if one does not exist, or returning
				// latest existing convo.)
				const { conversation } = await ServerStore.FindLatestConversation(
					person.id,
				);
				onSave({ person, conversation });
			}

			setLoading(false);

			// ServerStore.metric('app.choose_person.chose', null, {
			// 	person,
			// });
		}

		PersonQuery.searchString = '';
	};

	PersonQuery.setLoadingCallback(setLoading);

	const gotResults = (results, forceFirstRow) => {
		// Display results with changes
		setResults(results);
		if (!results) {
			setActiveRow(null);
			return;
		}

		// Enter pressed, so select first result
		if (PersonQuery.saveOnLoad) {
			// clear flag for next usage
			PersonQuery.saveOnLoad = false;

			if (results[0]) {
				choosePerson(results[0]);
				return;
			}
		}

		if (forceFirstRow && results[0]) {
			setActiveRow(results[0]);
			return;
		}

		// const { book, chapter, person } = activeRow || {};
		// if (book && chapter && person) {
		// 	const row = results.find((row) => {
		// 		return (
		// 			row.person === person &&
		// 			row.chapter === chapter &&
		// 			row.book.id === book.id
		// 		);
		// 	});
		// 	if (row) {
		// 		setActiveRow(row);
		// 	}
		// } else {
		setActiveRow(results[0]);
		// }
	};

	const search = async (text) => {
		if (!text) {
			PersonQuery.searchString = '';
			setResults([]);
			return;
		}

		if (text === PersonQuery.searchString) {
			return;
		}

		const res = await PersonQuery.search(text);
		gotResults(res, true);
	};

	const keyPressed = (ev) => {
		const KEY_DOWN = 40;
		const KEY_UP = 38;
		const KEY_ENTER = 13;
		const key = ev.which;

		if (key === KEY_ENTER) {
			if (loading) {
				// Will be reset in gotResults()
				PersonQuery.saveOnLoad = true;
			} else {
				ev.stopPropagation();
				ev.nativeEvent.stopImmediatePropagation();
				choosePerson(activeRow);
			}
		} else if (key === KEY_DOWN) {
			ev.stopPropagation();
			ev.nativeEvent.stopImmediatePropagation();

			let idx = results.indexOf(activeRow) + 1;
			if (idx >= results.length) {
				idx = results.length - 1;
			}
			setActiveRow(results[idx]);
		} else if (key === KEY_UP) {
			ev.stopPropagation();
			ev.nativeEvent.stopImmediatePropagation();

			let idx = results.indexOf(activeRow) - 1;
			if (idx < 0) {
				idx = 0;
			}
			setActiveRow(results[idx]);
		} else {
			// console.log("unknown key ", key);
		}
	};

	function HighlightHelper({ text }) {
		const matches = match(text, PersonQuery.searchString);
		const parts = parse(text, matches);
		return (
			<>
				{parts.map((part, index) => (
					<span
						key={index}
						className={clsx(part.highlight && styles.partHighlight)}
						style={{ fontWeight: part.highlight ? 700 : 400 }}
					>
						{part.text}
					</span>
				))}
			</>
		);
	}

	return (
		<Dialog className={styles.root} open={!!open} onClose={onCloseDialog}>
			<DialogTitle
				className={clsx(
					styles.searchBar,
					results && results.length && styles.hasResults,
				)}
			>
				<Input
					className={styles.search}
					disableUnderline
					type="search"
					placeholder="Search People"
					autoFocus
					onChange={(ev) => search(ev.target.value)}
					onKeyDown={(ev) => keyPressed(ev)}
				/>
			</DialogTitle>

			{results && results.length ? (
				<DialogContent className={styles.results}>
					<List dense className={styles.resultsList} ref={listRef}>
						{results.map((row) => (
							<ScrollIntoViewIfNeeded key={row.id} active={activeRow === row}>
								<ListItem
									button
									onClick={() => choosePerson(row)}
									className={clsx(
										styles.searchResult,
										activeRow === row && styles.active,
									)}
								>
									<div className={styles.resultWrap}>
										<span className={styles.ref}>
											<HighlightHelper text={row.name} />
										</span>
										<span className={styles.text}>
											{row.phoneNum ? (
												<HighlightHelper text={row.phoneNum} />
											) : (
												''
											)}
											{row.phoneNum && row.email && '|'}
											{row.email ? <HighlightHelper text={row.email} /> : ''}
										</span>
									</div>
								</ListItem>
							</ScrollIntoViewIfNeeded>
						))}
					</List>
				</DialogContent>
			) : !loading && PersonQuery.searchString ? (
				<>
					<DialogContent className={styles.noMatch}>
						<p>
							No existing people match <b>{PersonQuery.searchString}</b> - do
							you want to create a new record for{' '}
							<b>{PersonQuery.searchString}</b>?
						</p>
					</DialogContent>
				</>
			) : !loading ? (
				<>
					<DialogContent className={styles.bibleAppHint}>
						<p>Fresh hints go here...</p>
					</DialogContent>
				</>
			) : (
				''
			)}
			<DialogActions>
				<div className={styles.summary}>
					{results && results.length > 1 ? (
						<>
							{results.length >= 10 ? 'Top ' : ''}
							<b>{results.length}</b> people{' '}
						</>
					) : (
						''
					)}
					{loading ? <CircularProgress color="inherit" size={20} /> : ''}
				</div>
				{!loading && PersonQuery.searchString ? (
					<Button onClick={() => choosePerson()} color="primary">
						Add New Person
					</Button>
				) : (
					''
				)}
				<Button
					onClick={onCloseDialog}
					color="default"
					data-testtarget="cancel"
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
