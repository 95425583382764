import React from 'react';

const getWindowOrientation = () => {
	const { innerWidth: w, innerHeight: h } = window;
	const aspectRatio = w / h;
	const ref = {
		isLandscape: aspectRatio > 1,
		isPortrait: aspectRatio < 1,
		isSquare: aspectRatio === 1,
		aspectRatio,
		w,
		h,
	};

	// console.log(`useWindowOrientation: `, ref);
	return ref;
};

/**
 * Exposes a React state variable containing an object like `{ isLandscape: true, isPortrait: false, isSquare: false }` that automatically updates when the window changes shape
 * @returns {object} Object containing: `{ isLandscape: boolean, isPortrait: boolean, isSquare: boolean, aspectRatio: number, w: number, h: number }`
 */
export default function useWindowOrientation() {
	const [orientation, setOrientation] = React.useState(getWindowOrientation());
	React.useEffect(() => {
		const changed = () => {
			setOrientation(getWindowOrientation());
		};
		window.addEventListener('resize', changed);
		return () => window.removeEventListener('resize', changed);
	});

	return orientation;
}
