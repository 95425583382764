import React from 'react';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton } from '@material-ui/core';
import styles from './HeaderWidget.module.scss';
import CustomSkeleton from './CustomSkelton';
import MoreMenu from './MoreMenu';
import Head from './Head';

export default function HeaderWidget({
	loading,
	title,
	pageTitle,
	actions,
	menuTooltip,
	hideBackButton = false,
	position = 'fixed',
}) {
	return (
		<div className={clsx(styles.root, styles[`position-${position}`])}>
			<Head title={loading ? 'Loading...' : pageTitle || title} />
			<div className={styles.bar}>
				<IconButton
					onClick={() => window.history.go(-1)}
					style={{ opacity: hideBackButton ? 0 : 1 }}
				>
					<ChevronLeftIcon />
				</IconButton>

				<CustomSkeleton active={loading}>
					<h3>{loading ? 'Loading ...' : title}</h3>
				</CustomSkeleton>

				{actions && actions.length ? (
					<MoreMenu
						tooltip={menuTooltip}
						className={styles.moreMenu}
						actions={actions}
					/>
				) : (
					<div className={styles.missingMoreMenu} />
				)}
			</div>
		</div>
	);
}
