/* eslint-disable prettier/prettier */
/**
 * @namespace MessageTypes
 * Reusable type name definitions for messages both from websockets and emitted internally
 */
export const MessageTypes = {
	// Ping/Pong to keep nginx from killing sockets
	PingMessage:                        "ping_message",          
	PongMessage:                        "pong_message",         

	// Allow clients to sub/unsub from adapter pools.
	// Currently used for sub/unsub to conversations
	ClientPoolSubscribe:                "client_pool_sub_message",          
	ClientPoolUnsubscribe:              "client_pool_unsub_message",         
	
	// New Inbound Messages
	ConversationMessagesReceived:       "conversation_messages_received",
	MessageStatusUpdated:               "message_status_updated",
	ConversationUserUpdated:            "conversation_user_updated",

	// Attachment completed processing, etc
	AttachmentStatusUpdated:            "attachment_status_updated",

	// // TODO: Remove these if we don't have IOT device, just use hub in cloud?
	// StatusMessage:                      "status_message",          
	// GetStatusMessage:                   "get_system_status_message",     
	// SystemStatusMessage:                "system_state_message",          

	// // TODO: Remove these if we don't have IOT device, just use hub in cloud?
	// GetWorkspaceMessage:                  "get_workspace_message",           
	// WorkspaceMessage:                     "workspace_message",               
	// SyncWorkspaceMessage:                 "sync_proj_message",             

	// // TODO: Remove this if we don't have IOT device, just use hub in cloud?
	// UnprovisionMessage:                 "unprovision_message",           
	// UpgradeMessage:                     "upgrade_message",               

	// TODO: Remove this from clients?
	DebugSocketMessage:                 "debug_socket_message",          

	// Client implementation in shared TimeSyncUtil class
	TimeSyncMessage:                    "time_sync_message",             

	// Used during the auth flow BEFORE attempting auth to validate the PIN
	// and decide (a) if it's a valid PIN and (b) if it's "just" a workspace
	// PIN (which requires further auth), or if it's a user PIN which
	// then the client can re-transmit down as a PINAuthorizationMessage
	PINValidationMessage:               "pin_validation_message",         

	// Wraps HTTP calls ... users could call over HTTP, but if we already have
	// socket set up, this lets them re-use REST API.
	// Currently, as of this writing, they have to auth over socket anyway,
	// and socket doesn't require setup/teardown of an HTTP session for every call
	WebSocketMessageWrapper:            "websocket_message_wrapper",     

	// If more than one workspace, user must choose which workspace to sign in to.
	// This really only applies to users that are "special", e.g. not just camera/public users
	// (e.g. users that are have a role higher than 'isRecorder' on their WorkspaceUser entry.)
	GetWorkspacesForUserMessage:          "workspaces_for_user_message",
	// Could contain a "new workspace" flag to create a new one on the fly.
	// Do onboarding flow if first workspace?
	SetWorkspaceForUserMessage:           "set_workspace_for_user_message",

	//= ===================================================================
	// Authorization-related Messages
	//= ===================================================================

	// TBD: Disable anon?
	AnonymousAuthorizationMessage:      "anonymous_auth_msg",             
	// User/pas auth because social is annoying in dev
	UserPassAuthorizationMessage:       "userpass_auth_msg",              
	// Signup flow
	UserPassSignupMessage:              "userpass_signup_msg",            
	// Like user/pass, must be done after workspace identified though
	SocialAuthorizationMessage:         "social_auth_message",           
	// *  PIN is both manual-entry or QR-code
	// Enables easier user sign-in on mobile devices.
	// Note: This is only used if the PIN code is a "user" pin code (TBD).
	// "Workspace" PIN codes are used to identify the workspace,
	// then auth is done AFTER that...
	PINAuthorizationMessage:            "pin_authorization_message",     
	
	// Re-auth with existing stored token (refresh?)
	JWTAuthorizationMessage:            "jwt_auth_message",              
	
	// * Reply back telling client they are authd
	AuthorizedMessage:                  "authorized_message",            
	// * Auth failure
	UnauthorizedMessage:                "unauthorized_message",
	// * Client log-out
	DeauthorizationMessage:             "deauth_message",                

	//= ===================================================================
	// Switcher-specific message types
	//= ===================================================================
	// * SwitcherRegisteredMessage: Sent when the switcher comes online,
	//   sent back to the client with registration and RTC details
	SwitcherRegisteredMessage:          "switcher_registered_message",
	// * GetCamerasListMessage: Query sent to hub by switcher to get the list
	GetCamerasListMessage:              "get_cameras_list_message",      
	// * CamerasListMessage: Reply from hub to switcher with camera list
	CamerasListMessage:                 "cameras_list_message",          
	// * SwitcherCameraStatusMessage: Planned future use, not really used yet
	SwitcherCameraStatusMessage:        "switcher_camera_status_message",
	// * SwitchLiveCameraMessage: Sent by hub to switcher to indicate
	//   which camera should now be live
	SwitchLiveCameraMessage:            "switch_live_camera_message",     
	// * CameraStatusUpdatedMessage: Sent by Hub to Switcher with updated
	//   camera meta data, such as faces, etc - this is really used during
	//   development, might not be needed for prod
	CameraStatusUpdatedMessage:         "camera_status_updated_message",

	// Used by ApiService.updateWorkspaceMeta to notify the VideoStreamer
	// that's streaming the workspace if user changes output configs.
	// Irrelevant if VideoStreamer online at this time, because VideoStreamer
	// will pull outputs when it starts streaming anyway.
	WorkspaceOutputsChanged:              "workspace_outputs_changed",

	//= ===================================================================
	// Camera-specific message types
	//= ===================================================================
	// * RegisterCameraMessage: Sent by camera to hub to register the socket
	//   as a camera and RTC "sender". 
	RegisterCameraMessage:              "register_camera_message",     
	// * UnregisterCameraMessage: Sent by camera to hub when leaving camera mode
	UnregisterCameraMessage:            "unregister_camera_message",   
	// * CameraRegisteredMessage: Hub replies with same message containing
	//   RTC identification details and camera ID
	CameraRegisteredMessage:            "camera_registered_message",  
	// * ClientCameraStatusMessage: Generated by the hub containing latest 
	//   status information. Most importantly, has the 'latestAnalysis' data
	ClientCameraStatusMessage:          "client_camera_status_message",
	// * FrameAnalysisMessage: Generated by AnalysisService in the app and
	//   sent to the hub containing AI-generated analysis data to be processed
	//   by the hub and stored (including face previews, bas64 encoded)
	FrameAnalysisMessage:               "frame_analysis_message",    
	// * VideoEncoderStatusMessage: Generated by the app when the client-side
	//   encoder starts/stops for whatever reason so the hub can log or
	//   close messages accordingly
	VideoEncoderStatusMessage:          "video_encoder_status_message", 
	// * EncodedBlobHeaderMessage: Camera transmits encoded video every ~1000ms
	//   to the hub (compared to live streamed RTC video directly to the switcher)
	//   This msg is sent as the "header" to indicate the next WebSocket msg
	//   contains a raw blob of encoded video instead of stringified JSON
	EncodedBlobHeaderMessage:           "encoded_blob_header_message",  
	// * EventServiceStatusMessage: Sent by the hub when it's time to actually
	//   "do" the event. The client then starts/stops client-side analysis
	//   and message/encoding services.
	EventServiceStatusMessage:          "event_service_status_message",
	
	// TODO: Messages for uploading messages? Does client trigger/attempt,
	// or does hub request?

	//= ===================================================================
	// Misc Admin (non-camera) messages (set includes Switcher above 
	// when tested below)
	//= ===================================================================
	RegisterAdminMessage:               "register_admin_message",     
	UnregisterAdminMessage:             "unregister_admin_message",   
	FaceListMessage:                    "face_list_message",
	PeopleListMessage:                  "people_list_message",
	PersonCreatedMessage:               "person_created_message",
	MergePeopleMessage:                 "merge_people_message",
	DeleteFacesMessages:                "delete_faces_message",
	UpdatePersonMessage:                "update_person_message",

	MessageStatusMessage:             "message_status_message",

	//= ===================================================================
	// Common messages for RTC
	//= ===================================================================
	RTCCallInitMessage:                 "rtc_call_init_message",
	RTCSignalingMessage:                "rtc_signaling_message",
	RTCCallEndMessage:                  "rtc_call_end_message",

	//= ===================================================================
	// JobManager and worker nodes
	//= ===================================================================
	JobAvailable:                       "job_available_message",
	JobAccept:                          "job_accepted_message",
	JobWorkerPing:                      "job_worker_ping_message",
	JobWorkerDead:                      "job_worker_dead_message",
	JobCompleted:                       "job_completed_message",
	JobServerError:                     "job_server_error_message",
	JobCapacityChanged:                 "job_capacity_changed_message",
};
/* eslint-enable prettier/prettier */
export default MessageTypes;

export function isAuthorizationMessage(type) {
	return [
		// TBD: Disable anon?
		MessageTypes.AnonymousAuthorizationMessage,
		// User/pas auth because social is annoying in dev
		MessageTypes.UserPassAuthorizationMessage,
		// Signup flow
		MessageTypes.UserPassSignupMessage,
		// PIN is both manual-entry or QR-code
		// Enables easier user sign-in on mobile devices.
		// Note: This is only used if the PIN code is a "user" pin code (TBD).
		// "Workspace" PIN codes are used to identify the workspace,
		// then auth is done AFTER that...
		MessageTypes.PINAuthorizationMessage,
		// Like user/pass, must be done after workspace identified though
		MessageTypes.SocialAuthorizationMessage,
		// Re-auth with existing stored token (refresh?)
		MessageTypes.JWTAuthorizationMessage,
		// Reply back telling client they are authd
		MessageTypes.AuthorizedMessage,
		// Auth failure
		MessageTypes.UnauthorizedMessage,
		// Client log-out
		MessageTypes.DeauthorizationMessage,
	].includes(type);
}

export function isPublicMessage(type) {
	return [
		MessageTypes.TimeSyncMessage,
		MessageTypes.GetStatusMessage,
		MessageTypes.PINValidationMessage,
	].includes(type);
}

export function isWebRtcMessage(type) {
	return [
		MessageTypes.RTCCallInitMessage,
		MessageTypes.RTCCallEndMessage,
		MessageTypes.RTCSignalingMessage,
	].includes(type);
}

export function isJobMessage(type) {
	return [
		MessageTypes.JobAvailable,
		MessageTypes.JobAccept,
		MessageTypes.JobWorkerPing,
		MessageTypes.JobWorkerDead,
		MessageTypes.JobCompleted,
		MessageTypes.JobServerError,
		MessageTypes.JobCapacityChanged,
	].includes(type);
}

export function isPoolSubMessage(type) {
	return [
		MessageTypes.ClientPoolSubscribe,
		MessageTypes.ClientPoolUnsubscribe,
	].includes(type);
}

const SwitcherMessages = [
	MessageTypes.GetCamerasListMessage,
	MessageTypes.CamerasListMessage,
	MessageTypes.SwitcherCameraStatusMessage,
	MessageTypes.SwitchLiveCameraMessage,
];

export function isSwitcherMessage(type) {
	return SwitcherMessages.includes(type);
}

// TODO: Depreciate these - move this functionality elsewhere
export function isAdminMessage(type) {
	return [
		...SwitcherMessages,
		MessageTypes.RegisterAdminMessage,
		MessageTypes.UnregisterAdminMessage,
		MessageTypes.PeopleListMessage,
		MessageTypes.FaceListMessage,
		MessageTypes.PersonCreatedMessage,
		MessageTypes.MergePeopleMessage,
		MessageTypes.UpdatePersonMessage,
		MessageTypes.DeleteFacesMessages,
	].includes(type);
}

export function isCameraMessage(type) {
	return [
		MessageTypes.RegisterCameraMessage,
		MessageTypes.CameraRegisteredMessage,
		MessageTypes.ClientCameraStatusMessage,
		MessageTypes.FrameAnalysisMessage,
		MessageTypes.UnregisterCameraMessage,
		MessageTypes.VideoEncoderStatusMessage,
		MessageTypes.EncodedBlobHeaderMessage,
		MessageTypes.EventServiceStatusMessage,
	].includes(type);
}
