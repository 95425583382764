export default function formatTimestamp(
	timestamp,
	{ shortFormatNames = false, dateOnly = false } = {},
) {
	const d = new Date(timestamp);
	const dist = new Date() - d;
	const lessThanOneWeek = dist < 1000 * 60 * 60 * 24 * 7;

	const day = (shortFormatNames
		? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
		: [
				'Sunday',
				'Monday',
				'Tuesday',
				'Wednesday',
				'Thursday',
				'Friday',
				'Saturday',
		  ])[d.getDay()];
	const month = (shortFormatNames
		? [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
		  ]
		: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
		  ])[d.getMonth()];

	const hourNum = d.getHours();
	const minNum = d.getMinutes();
	const minutes = `${minNum < 10 ? '0' : ''}${minNum}`;
	const hours = `${
		// eslint-disable-next-line no-nested-ternary
		hourNum === 0 ? 12 : hourNum <= 12 ? hourNum : hourNum - 12
	}`;
	const amPm = hourNum < 12 ? 'AM' : 'PM';
	const date = `${month} ${d.getDate()}`;
	const time = `${hours}:${minutes} ${amPm}`;

	if (lessThanOneWeek) {
		return dateOnly ? day : `${day} · ${time}`;
	}

	if (dateOnly) {
		return shortFormatNames ? date : `${day}, ${date}`;
	}

	return `${day}, ${date} · ${time}`;
}
