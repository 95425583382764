import React, { useEffect } from 'react';
import styles from './DashboardPage.module.scss';
import { NavControlService, NavConfig } from '../PrimaryNav';

export default function DashboardPage() {
	useEffect(() => {
		// Has to exec inside an effect, otherwise get React rendering
		// errors about not updating a component while rendering this page
		NavControlService.showPrimaryNav(true, NavConfig.dashboard);
	});

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<h1>Welcome, Stephanie</h1>4 messages assigned to you
			</div>
		</div>
	);
}
