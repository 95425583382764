/* eslint-disable no-console */
import React, { useRef, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import RichTextEditor from 'react-rte';
import clsx from 'clsx';
import axios from 'axios';

import {
	TextareaAutosize,
	IconButton,
	Tooltip,
	MenuItem,
	FormControl,
	Select,
	ButtonBase,
	CircularProgress,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import CancelIcon from '@material-ui/icons/Cancel';

import { ServerStore } from 'utils/ServerStore';
import AppConfig from 'shared/config';
import styles from './ComposerWidget.module.scss';

function UploadWidget({ onUpload, results, maxSize, accept, onCancel }) {
	return (
		<div className={styles.uploadWidget}>
			{results.uploading ? ( // eslint-disable-line no-nested-ternary
				<>
					<section>
						<div>
							<CircularProgress style={{ color: 'white' }} />
							<br />
							Uploading...
						</div>
					</section>
				</>
			) : results.success ? ( // eslint-disable-line no-nested-ternary
				<>
					<section>
						<div>
							<span role="img" aria-label="success">
								&#9989;
							</span>{' '}
							Success
						</div>
					</section>
				</>
			) : results.failure ? (
				<>
					<section>
						<div>
							<span role="img" aria-label="failure">
								&#10060;
							</span>{' '}
							{results.error && results.error.message
								? results.error.message
								: ''}
						</div>
					</section>
				</>
			) : (
				<Dropzone
					onDrop={onUpload}
					maxSize={maxSize}
					accept={accept}
					multiple={false}
				>
					{({ getRootProps, getInputProps, isDragActive }) => (
						<section>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								{isDragActive ? (
									<p>Drop the files here ...</p>
								) : (
									<>
										<AttachFileIcon />
										<p>
											Drag and drop some files here, or click to select files
										</p>
									</>
								)}
							</div>
						</section>
					)}
				</Dropzone>
			)}

			<ButtonBase onClick={onCancel} className={styles.cancelButton}>
				Cancel
			</ButtonBase>
		</div>
	);
}

export default function ComposerWidget({
	loading,
	onSendMessage = () => {},
	// lastChannelType,
	lastSubject,
	conversationId,
	isSmsAllowed = true,
	isEmailAllowed = true,
}) {
	const [attachments, setAttachments] = useState([]);

	const [channel, setChannel] = useState(isEmailAllowed ? 'email' : 'sms');
	const onChangeChannel = (e) => {
		setChannel(e.target.value);
	};

	const [subject, setSubject] = useState('');
	const onSubjectChanged = (e) => {
		setSubject(e.target.value);
	};

	const [messageText, setMessageText] = useState('');
	const onMessageTextChanged = (e) => {
		setMessageText(e.target.value);
	};

	const [rteValue, setRteValue] = useState(RichTextEditor.createEmptyValue());

	const onRteChange = (value) => {
		setRteValue(value);
		setMessageText(value.toString('markdown'));
	};

	const rteToolbarConfig = {
		display: [
			'BLOCK_TYPE_DROPDOWN',
			'INLINE_STYLE_BUTTONS',
			'BLOCK_TYPE_BUTTONS',
			'LINK_BUTTONS',
			// 'HISTORY_BUTTONS',
		],
		INLINE_STYLE_BUTTONS: [
			{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
			{ label: 'Italic', style: 'ITALIC' },
			// {label: 'Underline', style: 'UNDERLINE'}
		],
		BLOCK_TYPE_DROPDOWN: [
			{ label: 'Normal', style: 'unstyled' },
			{ label: 'Heading Large', style: 'header-one' },
			{ label: 'Heading Medium', style: 'header-two' },
			{ label: 'Heading Small', style: 'header-three' },
		],
		BLOCK_TYPE_BUTTONS: [
			{ label: 'UL', style: 'unordered-list-item' },
			{ label: 'OL', style: 'ordered-list-item' },
		],
	};

	const inputsContainerRef = useRef();
	const onSendMessageClicked = () => {
		if (!messageText) {
			// alert('Nothing to send');
			return;
		}

		setAttachments([]);

		onSendMessage({
			channelType: channel,
			text: messageText,
			subject: channel === 'sms' ? null : subject,
		});
		// setSubject('');
		setMessageText('');
		setRteValue(RichTextEditor.createEmptyValue());

		setTimeout(() => {
			if (inputsContainerRef.current && channel === 'sms') {
				// The <TextareaAutosize> uses two textareas internally,
				// and the one we DON'T want to focus on has the
				// 'readonly' attribute ... so the OTHER one is what we want.
				const [textarea] = Array.from(
					inputsContainerRef.current.getElementsByTagName('textarea'),
				).filter((x) => !x.readonly);

				// console.warn(`>>>>> found textarea nodes:`, textarea);
				if (textarea) {
					textarea.focus();
				}
			} else {
				console.error(`not focusing because:`, {
					inputsContainerRef: inputsContainerRef.current,
					channel,
				});
			}
		}, 500);
	};

	// Import external type so we can change when user changes mode
	// const extChanType = useRef();
	// if (lastChannelType && extChanType.current !== lastChannelType) {
	// 	setChannel(lastChannelType);
	// 	extChanType.current = lastChannelType;
	// }

	const extSubject = useRef();
	if (lastSubject && extSubject.current !== lastSubject) {
		setSubject(
			lastSubject.startsWith('Re:') ? lastSubject : `Re: ${lastSubject}`,
		);
		extSubject.current = lastSubject;
	}

	const draftGuard = useRef();
	useEffect(() => {
		if (draftGuard.current || !conversationId) {
			return;
		}
		draftGuard.current = true;

		ServerStore.DraftAttachments(conversationId)
			.then(({ draftAttachments }) => {
				setAttachments(Array.from(draftAttachments || []));
			})
			.catch((error) => {
				console.error(`Error getting drafts:`, error);
			});
	});

	const [uploadWidgetVisible, setUploadWidgetVisible] = useState(false);
	const [fileUploadResult, setFileUploadResult] = useState({});
	const [uploadAccept, setUploadAccept] = useState('');
	const [uploadMaxSize, setUploadMaxSize] = useState();
	const showUploadWidget = (flag = true, accept = '', maxSize) => {
		setFileUploadResult({});
		setUploadWidgetVisible(!!flag);
		setUploadAccept(accept);
		setUploadMaxSize(maxSize);
	};

	const removeAttachment = (id) => {
		setAttachments(attachments.filter((a) => a.id !== id));
		ServerStore.RemoveAttachment(id);
	};

	const uploadFileToApi = (files) => {
		setFileUploadResult({ uploading: true });

		const formData = new FormData();
		formData.set('conversationId', conversationId);
		formData.append('file', files[0]);

		const { token } = ServerStore.server();
		axios
			.post(`//${AppConfig.apiHost}/api/v1/upload-attachment`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: token,
				},
			})
			.then((result) => {
				const { data: attachment } = result;
				// console.log(`Got attachment object from server:`, attachment);
				setFileUploadResult({ success: true });
				setAttachments([...attachments, attachment]);
				setTimeout(() => showUploadWidget(false), 500);
			})
			.catch((error) => {
				console.error(`caught error upload:`, error);
				setFileUploadResult({ failure: true, error });
			});
	};

	const onAddAttachmentClicked = () => {
		showUploadWidget(!uploadWidgetVisible, '');
	};

	const onAttachImageClicked = () => {
		// Max image size for media on Twilio is 5 MB
		showUploadWidget(!uploadWidgetVisible, 'image/*,video/*', 5 * 1024 * 1024);
	};

	return (
		<div className={styles.root}>
			{uploadWidgetVisible ? (
				<UploadWidget
					onCancel={() => showUploadWidget(false)}
					onUpload={uploadFileToApi}
					results={fileUploadResult}
					accept={uploadAccept}
					maxSize={uploadMaxSize || 256 * 10124 * 1024}
				/>
			) : (
				''
			)}
			<div className={styles.inputs} ref={inputsContainerRef}>
				{isSmsAllowed && isEmailAllowed ? (
					<Tooltip title="Channel defines how the messages is sent to the person in this chat">
						<div className={styles.preInputWidget}>
							<FormControl className={styles.channelSelect} disabled={loading}>
								<Select value={channel} onChange={onChangeChannel} tabIndex="1">
									<MenuItem value={'sms'}>Text</MenuItem>
									<MenuItem value={'email'}>Email</MenuItem>
								</Select>
							</FormControl>
						</div>
					</Tooltip>
				) : (
					''
				)}

				{channel === 'email' ? (
					<>
						<Tooltip title="Add attachment">
							{/* Span wrapper needed to still fire events
							for tooltip to display messages
							even if button is disabled due to loading */}
							<span>
								<IconButton
									onClick={onAddAttachmentClicked}
									disabled={loading}
									tabIndex="2"
								>
									<AttachFileIcon />
								</IconButton>
							</span>
						</Tooltip>
						<TextareaAutosize
							rowsMax={2}
							placeholder={'Subject'}
							value={subject}
							onChange={onSubjectChanged}
							disabled={loading}
							className={styles.subjectField}
							tabIndex="3"
						/>
					</>
				) : (
					<>
						<Tooltip title="Attach Image">
							{/* Span wrapper needed to still fire events
					for tooltip to display messages
					even if button is disabled due to loading */}
							<span>
								<IconButton
									className={styles.addImageBtn}
									onClick={onAttachImageClicked}
									disabled={loading}
									tabIndex="2"
								>
									<InsertPhotoIcon />
								</IconButton>
							</span>
						</Tooltip>

						<TextareaAutosize
							rowsMax={10}
							placeholder={'Type a message here'}
							value={messageText}
							onChange={onMessageTextChanged}
							disabled={loading}
							tabIndex="3"
						/>
					</>
				)}

				{/* <Tooltip title="Send Message"> */}
				{/* Span wrapper needed to still fire events
				for tooltip to display messages
				even if button is disabled due to loading */}
				<span>
					<IconButton
						onClick={onSendMessageClicked}
						className={styles.sendMessageBtn}
						disabled={loading}
						tabIndex="10"
					>
						<SendIcon />
					</IconButton>
				</span>
				{/* </Tooltip> */}
			</div>

			<div className={styles.inputs}>
				{channel === 'email' ? (
					<>
						<RichTextEditor
							className={styles.rteEditor}
							value={rteValue}
							onChange={onRteChange}
							toolbarConfig={rteToolbarConfig}
							placeholder="Type your email here"
							tabIndex="4"
						/>
					</>
				) : (
					''
				)}
			</div>

			<div className={clsx(styles.inputs, styles.attachmentsRow)}>
				{attachments.map(({ id, name }) => (
					<Tooltip key={id} title={`Remove "${name}" from this message`}>
						<ButtonBase
							className={styles.attachment}
							onClick={() => removeAttachment(id)}
						>
							<div className={styles.icon}>
								<AttachFileIcon />
								<div className={styles.cancelIcon}>
									<CancelIcon />
								</div>
							</div>

							<span className={styles.label}>{name}</span>
						</ButtonBase>
					</Tooltip>
				))}
			</div>
		</div>
	);
}
