/* eslint-disable no-shadow, no-alert */
import React from 'react';
// import QRCode from 'qrcode.react';
// import history from 'utils/history';
import {
	List,
	ListItem,
	Tooltip,
	Typography,
	// ButtonBase,
	// FormControlLabel,
	IconButton,
	// Input,
	// Switch,
} from '@material-ui/core';
// import RefreshIcon from '@material-ui/icons/Refresh';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import formatPhone from 'shared/utils/formatPhone';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HubService from '../../utils/hub/HubService';
import CustomSkeleton from '../CustomSkelton';
import styles from './SettingsPage.module.scss';
import { NavConfig, NavControlService } from '../PrimaryNav';
// import { ServerStore } from '../../utils/ServerStore';
import HeaderWidget from '../HeaderWidget';
import ListItemLink from '../ListItemLink';

export default function SettingsPage() {
	React.useEffect(() => {
		// Has to exec inside an effect, otherwise get React rendering
		// errors about not updating a component while rendering this page
		NavControlService.showPrimaryNav(true, NavConfig.settings);
	});

	// If still loading connection, returns undefined.
	// If loaded but LoginRequired, redirects to /login (leaves this page)
	// If loaded and authorized, returns true
	HubService.useAuthRequired();

	const { user } = HubService.useAuthorization() || {};
	const { /* email, name, */ id } = user || { id: '' };
	// const defaultDisplay = name || email || (id && `User # ${id.split('-')[0]}`);
	// const [display, setDisplay] = React.useState(defaultDisplay);

	// const [otherLoading, setOtherLoading] = React.useState(false);

	// const displayGuard = React.useRef();
	// if (displayGuard.current !== id) {
	// 	displayGuard.current = id;
	// 	setDisplay(defaultDisplay);
	// }

	// const workspaceId = HubService.getWorkspaceId() || '';
	// const workspaceIdDisplay = workspaceId && `Workspace # ${workspaceId.split('-')[0]}`;

	const {
		// pin,
		// id: workspaceId,
		name: workspaceName,
		phoneNum: workspacePhoneNum,
		// email: workspaceEmail,
		numPeople,
		numUsers,
	} = HubService.useWorkspace() || {};
	// const workspaceIdDisplay =
	// 	workspaceId && `Workspace # ${workspaceId.split('-')[0]}`;
	// const qrUrl = `${window.location.protocol}//${window.location.host}/#/pin/${pin}`;

	// console.log({ qrUrl });

	// const onLogout = () => {
	// 	HubService.logout();
	// };

	// const onProjBtn = () => {
	// 	HubService.activeState.setState(HubService.ActiveStates.WorkspaceRequired);
	// 	history.push('/login/workspace');
	// };

	// const onNameClicked = async () => {
	// 	const name = window.prompt('Update your name:', display);
	// 	if (!name) {
	// 		return;
	// 	}

	// 	setDisplay(name);

	// 	setOtherLoading(true);

	// 	await ServerStore.UpdateUserProps({ name });
	// 	// Because other parts of app might use this data e.g. schedule page
	// 	// and have user hardcoded in replies, so update entire app
	// 	window.location.reload();
	// };

	// const onWorkspaceNameClicked = async () => {
	// 	const name = window.prompt('Edit workspace name:', workspaceName);
	// 	if (!name) {
	// 		return;
	// 	}

	// 	// Only show loading indicator if taking longer than 1/3rd second to load
	// 	const tid = setTimeout(() => setOtherLoading(true), 333);

	// 	await HubService.updateWorkspaceMeta({ name });

	// 	clearTimeout(tid);
	// 	setOtherLoading(false);
	// };

	// const regeneratePin = async () => {
	// 	// Only show loading indicator if taking longer than 1/3rd second to load
	// 	const tid = setTimeout(() => setOtherLoading(true), 333);

	// 	await HubService.updateWorkspaceMeta({ regeneratePin: true });

	// 	clearTimeout(tid);
	// 	setOtherLoading(false);
	// };

	const loading = !id; // || otherLoading;

	const settingsPrompt = (/* field */) => {
		// const fieldOptions = {
		// 	name: {
		// 		label: 'Name',
		// 		type: 'text',
		// 		value: name,
		// 		field: 'name',
		// 	},
		// 	email: {
		// 		label: 'E-mail',
		// 		type: 'email',
		// 		value: email,
		// 		field: 'email',
		// 	},
		// 	phoneNum: {
		// 		label: 'Phone Number',
		// 		type: 'tel',
		// 		value: phoneNum,
		// 		field: 'phoneNum',
		// 	},
		// };
		// setEditOptions(fieldOptions[field]);
		// setEditOpen(true);
	};

	return (
		<>
			<HeaderWidget title={'Settings'} loading={loading} />

			{/* <ValueEditDialog
			options={editOptions}
			open={editOpen}
			onSave={onEditSave}
			onCancel={onEditCancel}
		/>
 		*/}

			<div className={styles.root}>
				<List className={styles.fieldList} dense>
					<Tooltip title="Change the name of the workspace">
						<ListItem
							button
							className={styles.myListItem}
							onClick={() => settingsPrompt('workspace.name')}
						>
							<Typography variant="body2">Workspace Name</Typography>
							<CustomSkeleton active={loading}>
								<Typography align="right" className={styles.value}>
									{loading ? 'John Doe' : workspaceName}
								</Typography>
							</CustomSkeleton>
						</ListItem>
					</Tooltip>
					<Tooltip title="Change the email of the workspace">
						<ListItem
							button
							className={styles.myListItem}
							onClick={() => settingsPrompt('workspace.email')}
						>
							<Typography variant="body2">Workspace Email</Typography>
							<CustomSkeleton active={loading}>
								<Typography align="right" className={styles.value}>
									{loading ? (
										'test@email.com'
									) : (
										<>
											<span className={styles.emailCode}>dev1</span>
											<span className={styles.emailDomain}>
												@mail.chatbetter.io
											</span>
										</>
									)}
								</Typography>
							</CustomSkeleton>
						</ListItem>
					</Tooltip>
					<Tooltip title="Change the phone number of the person">
						<ListItem
							button
							className={styles.myListItem}
							onClick={() => settingsPrompt('workspace.phoneNum')}
						>
							<Typography variant="body2">Workspace Phone</Typography>
							<CustomSkeleton active={loading}>
								<Typography align="right" className={styles.value}>
									{formatPhone(loading ? '+1000000000' : workspacePhoneNum)}
								</Typography>
							</CustomSkeleton>
						</ListItem>
					</Tooltip>

					<div className={styles.listDivider} />

					<Tooltip title="View/edit Team Members">
						<ListItem
							button
							className={styles.myListItem}
							onClick={() => settingsPrompt('workspace.phoneNum')}
						>
							<Typography variant="body2">Workspace Team Members</Typography>
							<CustomSkeleton active={loading}>
								<div className={styles.endButtonWithLabel}>
									<Typography align="right" className={styles.value}>
										{numUsers} members
									</Typography>
									<IconButton>
										<ChevronRightIcon />
									</IconButton>
								</div>
							</CustomSkeleton>
						</ListItem>
					</Tooltip>

					<Tooltip title="View/edit People">
						<ListItemLink href="#/people" className={styles.myListItem}>
							<Typography variant="body2">Contacts</Typography>
							<CustomSkeleton active={loading}>
								<div className={styles.endButtonWithLabel}>
									<Typography align="right" className={styles.value}>
										{numPeople} contacts
									</Typography>
									<IconButton>
										<ChevronRightIcon />
									</IconButton>
								</div>
							</CustomSkeleton>
						</ListItemLink>
					</Tooltip>
				</List>

				{/* <div className={styles.content}>
					<CustomSkeleton active={loading}>
						<ButtonBase onClick={onNameClicked}>
							<h3 className={styles.userDisplay}>{display}</h3>
						</ButtonBase>
					</CustomSkeleton>
					<CustomSkeleton active={loading}>
						<ButtonBase onClick={onWorkspaceNameClicked}>
							<h1 className={styles.workspaceIdDisplay}>
								{workspaceName || workspaceIdDisplay}
							</h1>
						</ButtonBase>
					</CustomSkeleton>

					<CustomButton
						loading={loading}
						onClick={onProjBtn}
						className={styles.btnProj}
					>
						Switch / Add workspace…
					</CustomButton>

					<CustomButton
						loading={loading}
						onClick={onLogout}
						className={styles.btnLogout}
					>
						Logout
					</CustomButton>
				</div> */}
			</div>
		</>
	);
}
