import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

export default function MoreMenu({ actions, tooltip, ...props }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mounted, setMounted] = React.useState(false);

	const mountedGuard = React.useRef();
	React.useEffect(() => {
		mountedGuard.current = true;
		return () => {
			mountedGuard.current = false;
		};
	});

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setMounted(true);
	};

	const handleClose = () => {
		setAnchorEl(null);
		// Let animation finish
		setTimeout(() => {
			if (mountedGuard.current) {
				setMounted(false);
			}
		}, 500);
	};

	if (!props.className) {
		// eslint-disable-next-line no-param-reassign
		props.style = {
			position: 'absolute',
			top: 0,
			right: 0,
			zIndex: 1,
			...(props.style || {}),
		};
	}

	return (
		<>
			<Tooltip title={tooltip || 'More...'}>
				<IconButton aria-haspopup="true" {...props} onClick={handleClick}>
					<MoreVertIcon fontSize="small" />
				</IconButton>
			</Tooltip>
			{mounted ? (
				<Menu
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{Array.from(actions || []).map(
						// eslint-disable-next-line no-shadow
						({ text, icon: IconComponent, onClick, ...props }, idx) => (
							<MenuItem
								key={idx}
								onClick={() => {
									handleClose();
									if (onClick) {
										onClick();
									}
								}}
								{...(props || {})}
							>
								{IconComponent ? (
									<>
										<ListItemIcon>
											<IconComponent fontSize="small" />
										</ListItemIcon>
										<Typography variant="inherit" noWrap>
											{text}
										</Typography>
									</>
								) : (
									text
								)}
							</MenuItem>
						),
					)}
				</Menu>
			) : (
				''
			)}
		</>
	);
}
