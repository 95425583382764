import Logger from './Logger';

export const isNode = global.process && process.release.name === 'node';

/**
 * Exposes `shared/Logger` if ran under node, otherwise, exposes `window.console` - all methods shared (debug, warn, etc)
 */
const localLogger = isNode ? Logger : window.console;

if (!isNode) {
	// Remap console.debug > console.log since all code that uses
	// Logger will use .debug instead of .log for the lowest level
	localLogger.debug = window.console.log;
}

export default localLogger;
