/* eslint-disable no-console */
import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import {
	List,
	ListItem,
	// ListItemText,
	Fab,
	Zoom,
	Tooltip,
} from '@material-ui/core';

import { VariableSizeList as VirtualList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import AddIcon from '@material-ui/icons/Add';
import formatTimestamp from 'shared/utils/formatTimestamp';
import { MessageTypes } from 'shared/utils/MessageTypes';
import AddConvoDialog from '../AddConvoDialog/AddConvoDialog';

import { ServerStore } from '../../utils/ServerStore';
import HubService, { useMessageHook } from '../../utils/hub/HubService';
import styles from './ConversationsList.module.scss';

import CustomSkeleton from '../CustomSkelton';
import PersonAvatar from '../PersonAvatar';
import useInitializedStateCache from '../../utils/useInitializedStateCache';
import SimpleBadge from '../SimpleBadge';

// TBD if we need to get more complex:
// https://material-ui.com/guides/composition/#caveat-with-inlining
function ListItemLink(props) {
	return <ListItem button component="a" {...props} />;
}

const ConversationSkelton = () => {
	return (
		<ListItem>
			<div
				style={{
					width: '100%',
				}}
			>
				<CustomSkeleton
					variant="rect"
					width={'100%'}
					height={'1.25rem'}
					style={{
						borderRadius: '4px',
						marginBottom: '.5rem',
						marginTop: '.25rem',
					}}
				/>
				<CustomSkeleton
					variant="rect"
					width={'100%'}
					height={'1rem'}
					style={{
						borderRadius: '4px',
					}}
				/>
			</div>
		</ListItem>
	);
};

export default function ConversationsList({ onConvoSelected, doublePaneView }) {
	const [loading, setLoading] = useState(true);

	const conversationsCache = useInitializedStateCache(
		async ({ initialStateCached }) => {
			if (!initialStateCached) {
				setLoading(true);
			}
			const results = await ServerStore.GetConversationsList();
			setLoading(false);
			return Array.from(results.conversations || []);
		},
		{
			initialValue: [],
			persistKey: 'ConversationsList',
		},
	);

	const conversations = Array.from(conversationsCache.state || []);

	// Use this to clear the focus off the button when we open the dialog
	const btnRef = useRef();

	const [addConvoDialogOpen, setAddConvoDialogOpen] = useState(false);

	const onAddConvoSave = ({ person, conversation, isNew }) => {
		setAddConvoDialogOpen(false);
		console.log(`onAddConvoSave:`, { person, conversation, isNew });
		onConvoSelected(conversation);
	};

	const onAddConvoCancel = () => {
		setAddConvoDialogOpen(false);
	};

	const openAddConvoDialog = () => {
		if (btnRef.current) {
			// If we don't clear the focus, then in the dialog,
			// if user presses enter to select highlighted row,
			// then the enter propagates and re-opens the dialog immediately
			btnRef.current.blur();
		}
		setAddConvoDialogOpen(true);
	};

	useMessageHook(({ type, ...data }) => {
		// console.log(`ConversationPage messageHooks got type:`, type, data);

		if ([MessageTypes.ConversationMessagesReceived].includes(type)) {
			console.warn(`++ Got new message over socket: `, data);

			const { message, conversationId } = data;
			const entry = conversations.find(({ id }) => id === conversationId);

			if (entry) {
				// If convo already in list, we can just update props
				// from websocket message and re-render
				const { timestamp, text, inbound } = message;
				Object.assign(entry, {
					numNewMessages: 1, // just need 1 to highlight
					timestamp,
					text,
					inbound,
				});

				console.log(` ** convo found in list, updated entry:`, { ...entry });

				// Resort in case timestamp changed the position in the list
				const sortedList = conversations.sort(
					(a, b) => new Date(b.timestamp) - new Date(a.timestamp),
				);

				// Copy to force re-render in case sort returns ref to orig list
				// setConversations([...sortedList]);
				conversationsCache.setState([...sortedList]);
			} else {
				console.log(` -- convo not in list, reloading`);
				// Convo from the websocket not in our list,
				// so reload entire list from server to add convo to the list
				// at the proper spot
				conversationsCache.reloadState();
			}

			// return true;
		} else if (type === MessageTypes.ConversationUserUpdated) {
			console.warn(`** Got convo user update over socket: `, data);
			const { numNewMessages, userId, conversationId } = data;

			// All users in current workspace get this message, but only the
			// current user's UI cares about it
			const { id: currentUserId } = HubService.getUser();
			if (userId !== currentUserId) {
				console.log(` - not for current user, ignoring`, { currentUserId });
			}
			const entry = conversations.find(({ id }) => id === conversationId);

			// If entry is NOT in the list, that's okay - just means
			// the UI doesn't care if numNewMessages changed
			if (entry) {
				// Update num messages
				Object.assign(entry, { numNewMessages });

				// Copy to force re-render in case sort returns ref to orig list
				conversationsCache.setState([...conversations]);
			}
		}

		return false; // don't intercept
	});

	// Used for virtualized list renderer
	const listRef = React.useRef({});
	const rowHeights = React.useRef({});

	// Used for virtualized list renderer
	function getRowHeight(index) {
		return rowHeights.current[index] + 0 || 67;
	}

	// Used for virtualized list renderer
	function setRowHeight(index, size) {
		if (listRef.current) {
			listRef.current.resetAfterIndex(0);
		}
		rowHeights.current = { ...rowHeights.current, [index]: size };
	}

	// Used for virtualized list renderer
	// eslint-disable-next-line react/prop-types
	function ConversationRow({ index, style }) {
		const rowRef = React.useRef({});

		React.useEffect(() => {
			if (rowRef.current) {
				setRowHeight(index, rowRef.current.clientHeight);
			}
		}, [index, rowRef]);

		// Grab complete ref for onConvoSelected cb
		const conversation = conversations[index];

		const {
			id,
			timestamp,
			personName,
			text,
			inbound,
			numNewMessages,
		} = conversation;

		const RowComponent = doublePaneView ? ListItem : ListItemLink;

		return (
			<div style={style} ref={rowRef}>
				<RowComponent
					key={id}
					button
					href={`#/conversation/${id}`}
					className={clsx(
						styles.conversation,
						parseInt(numNewMessages, 10) > 0 && styles.hasNewMessages,
					)}
					onClick={() => onConvoSelected(conversation)}
				>
					<div className={styles.avatarWrap}>
						<PersonAvatar person={{ name: personName }} inbound={true} />
					</div>
					<div className={styles.infoWrap}>
						<div className={styles.topWrap}>
							<div className={styles.name}>
								{personName}{' '}
								{parseInt(numNewMessages, 10) > 0 && (
									<SimpleBadge className={styles.badge}>
										{numNewMessages} unread
									</SimpleBadge>
								)}
							</div>
							{timestamp ? (
								<div className={styles.timestamp}>
									{formatTimestamp(timestamp, {
										shortFormatNames: true,
										dateOnly: true,
									})}
								</div>
							) : (
								''
							)}
						</div>
						{text ? (
							<div className={styles.previewWrap}>
								{!inbound ? <i>You: </i> : ''}
								{text}
							</div>
						) : (
							''
						)}
					</div>
				</RowComponent>
			</div>
		);
	}

	return (
		<div className={clsx(styles.root, doublePaneView && styles.doublePaneView)}>
			{!loading && conversations.length && (
				<AutoSizer>
					{({ height, width }) => {
						return (
							<VirtualList
								height={height}
								itemCount={conversations.length}
								itemSize={getRowHeight}
								ref={listRef}
								width={width}
							>
								{ConversationRow}
							</VirtualList>
						);
					}}
				</AutoSizer>
			)}

			<List>
				{loading && (!conversations || !conversations.length) && (
					<>
						<ConversationSkelton />
						<ConversationSkelton />
						<ConversationSkelton />
					</>
				)}

				{/* {!loading && !conversations.length ? (
					<>
						<ListItem button disabled>
							<ListItemText primary={<i>&nbsp;No conversations yet</i>} />
						</ListItem>
					</>
				) : (
					''
				)} */}
			</List>

			<AddConvoDialog
				open={addConvoDialogOpen}
				onSave={onAddConvoSave}
				onCancel={onAddConvoCancel}
			/>
			<Zoom
				in={true}
				timeout={{
					enter: 300,
					exit: 300,
				}}
				unmountOnExit
			>
				<Tooltip title="New conversation">
					<Fab
						size="medium"
						onClick={openAddConvoDialog}
						className={styles.addConvoFab}
						ref={btnRef}
					>
						<AddIcon />
					</Fab>
				</Tooltip>
			</Zoom>
		</div>
	);
}
