/* eslint-disable no-console */
import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import {
	List,
	ListItem,
	ListItemText,
	Fab,
	Zoom,
	Tooltip,
} from '@material-ui/core';

import { VariableSizeList as VirtualList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';

import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import { MessageTypes } from 'shared/utils/MessageTypes';

import formatPhone from 'shared/utils/formatPhone';
import { defer } from 'shared/utils/defer';
import AddConvoDialog from '../AddConvoDialog/AddConvoDialog';

import { ServerStore } from '../../utils/ServerStore';
// import useAsyncParam from '../../utils/useAsyncParam';
import history from '../../utils/history';
// import { useMessageHook } from '../../utils/hub/HubService';
import styles from './PeopleList.module.scss';

import CustomSkeleton from '../CustomSkelton';
import PersonAvatar from '../PersonAvatar';

// TBD if we need to get more complex:
// https://material-ui.com/guides/composition/#caveat-with-inlining
function ListItemLink(props) {
	return <ListItem button component="a" {...props} />;
}
const ConversationSkelton = () => {
	return (
		<ListItem>
			<div
				style={{
					width: '100%',
				}}
			>
				<CustomSkeleton
					variant="rect"
					width={'100%'}
					height={'1.25rem'}
					style={{
						borderRadius: '4px',
						marginBottom: '.5rem',
						marginTop: '.25rem',
					}}
				/>
				<CustomSkeleton
					variant="rect"
					width={'100%'}
					height={'1rem'}
					style={{
						borderRadius: '4px',
					}}
				/>
			</div>
		</ListItem>
	);
};

// const formatDate = (string) => {
// 	return new Date(string).toLocaleString();
// };

class SearchQuery {
	static setLoadingCallback(cb) {
		this.loadingCallback = cb;
	}

	static async setVersionId(versionId = '') {
		const changed = this.versionId !== versionId;

		this.versionId = versionId;

		// For notifying server of new version
		this.oldVersionId = this.lastSearchedVersionId;

		if (this.searchPromise) {
			return this.searchPromise;
		}

		if (this.searchString && changed) {
			return this.search(this.searchString);
		}
		return { list: [] };
	}

	static stop() {
		this.stopped = true;
		if (this.loadingCallback) {
			this.loadingCallback(false);
		}
	}

	static async _search() {
		const query = {
			search: this.searchString,
			// version: this.versionId,
		};

		// if(this.oldVersionId !== this.versionId) {
		// 	query.oldVersion   = this.oldVersionId;
		// }

		// console.log("[_search] oldVersionId is ", this.oldVersionId, ", this.lastSearchedVersionId is ", this.lastSearchedVersionId,", and query is:", query);

		// this.lastSearchedVersionId = this.versionId;

		const list = await ServerStore.GetPeople(query);

		// console.log("Serach got list: ", list);

		if (this.searchString !== query.search) {
			// console.warn("Search changed while waiting for results, not resolving");
			return;
		}

		if (this.stopped) {
			// console.warn("Stopped while waiting for results, not resolving");
			return;
		}

		if (this.loadingCallback) {
			this.loadingCallback(false);
		}

		// ServerStore.metric("app.search_page.searched", null, {
		// 	query: query.searchString
		// });

		// console.log("For query ", this.searchString, ", got:", list);

		if (this.searchPromise) {
			this.searchPromise.resolve(list);
			this.searchPromise = null;
		}
	}

	static async search(string = '') {
		if (this.loadingCallback) {
			this.loadingCallback(true);
		}

		this.stopped = false;
		this.searchString = string;

		if (!this.searchPromise) {
			this.searchPromise = defer();
		}

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._search();
		}, this.delay || 500);

		return this.searchPromise;
	}
}

export default function PeopleList() {
	const [results, setResults] = React.useState([]);
	const [loading, setLoading] = React.useState();
	const [firstLoad, setFirstLoad] = React.useState(true);

	SearchQuery.setLoadingCallback(setLoading);

	const gotResults = ({ list, ...props }) => {
		console.log('gotResults:', { list, props });
		// Display results with changes
		setResults(list);
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 1);
	};

	const execSearch = async (text) => {
		const res = await SearchQuery.search(text);
		gotResults(res, true);
	};

	const search = async (text) => {
		// if(!text) {
		// 	SearchQuery.searchString = "";
		// 	setResults([]);
		// 	return;
		// }

		if (text && text === SearchQuery.searchString) {
			return;
		}

		execSearch(text);
	};

	if (firstLoad) {
		search();
		setFirstLoad(false);
	}

	const people = Array.from(results || []);

	// Use this to clear the focus off the button when we open the dialog
	const btnRef = useRef();

	const [addConvoDialogOpen, setAddConvoDialogOpen] = useState(false);

	const onAddConvoSave = ({ person, conversation, isNew }) => {
		setAddConvoDialogOpen(false);
		console.log(`onAddConvoSave:`, { person, conversation, isNew });
		if (isNew) {
			// listMeta.reloadData();
			execSearch(SearchQuery.searchString);
		}

		history.push(`/people/${person.id}`);
	};

	const onAddConvoCancel = () => {
		setAddConvoDialogOpen(false);
	};

	const openAddConvoDialog = () => {
		if (btnRef.current) {
			// If we don't clear the focus, then in the dialog,
			// if user presses enter to select highlighted row,
			// then the enter propagates and re-opens the dialog immediately
			btnRef.current.blur();
		}
		setAddConvoDialogOpen(true);
	};

	// useMessageHook(({ type, ...data }) => {
	// 	// console.log(`PeopleList messageHooks got type:`, type, data);

	// 	if ([MessageTypes.ConversationMessagesReceived].includes(type)) {
	// 		console.warn(`++ Got new message over socket, reloading list: `, data);

	// 		// listMeta.reloadData();
	// 		execSearch(SearchQuery.searchString);
	// 	}

	// 	return false; // don't intercept
	// });

	// Used for virtualized list renderer
	const listRef = React.useRef({});
	const rowHeights = React.useRef({});

	// Used for virtualized list renderer
	function getRowHeight(index) {
		return rowHeights.current[index] + 0 || 48;
	}

	// Used for virtualized list renderer
	function setRowHeight(index, size) {
		if (listRef.current) {
			listRef.current.resetAfterIndex(0);
		}
		rowHeights.current = { ...rowHeights.current, [index]: size };
	}

	// Used for virtualized list renderer
	// eslint-disable-next-line react/prop-types
	function ContactRow({ index, style }) {
		const rowRef = React.useRef({});

		React.useEffect(() => {
			if (rowRef.current) {
				setRowHeight(index, rowRef.current.clientHeight);
			}
		}, [index, rowRef]);

		const { id, name, email, phoneNum } = people[index];

		return (
			<div style={style} ref={rowRef}>
				<ListItemLink
					key={id}
					button
					href={`#/people/${id}`}
					className={clsx(styles.person)}
				>
					<div className={styles.avatarWrap}>
						<PersonAvatar person={{ name }} inbound={true} />
					</div>
					<div className={styles.infoWrap}>
						<div className={styles.topWrap}>
							<div className={styles.name}>{name}</div>
						</div>
						<div className={styles.previewWrap}>
							<span className={styles.phoneNum}>{formatPhone(phoneNum)}</span>{' '}
							{email && phoneNum && <>&middot;</>} {email}
						</div>
					</div>
				</ListItemLink>
			</div>
		);
	}

	return (
		<div className={styles.root}>
			<AppBar position="relative" className={styles.searchBar}>
				<IconButton onClick={() => window.history.go(-1)}>
					<ChevronLeftIcon />
				</IconButton>
				<div className={styles.searchWrap}>
					<IconButton
						onClick={() => execSearch(SearchQuery.searchString)}
						className={styles.searchButton}
					>
						<SearchIcon style={{ fontSize: '1rem' }} />
					</IconButton>
					<Input
						className={styles.search}
						disableUnderline
						defaultValue={''}
						type="search"
						placeholder="Search People"
						onChange={(ev) => search(ev.target.value)}
						// onKeyDown={ev => keyPressed(ev)}
					/>
				</div>

				{/* </Toolbar> */}
			</AppBar>

			{!loading && people.length && (
				<AutoSizer>
					{({ height, width }) => (
						<VirtualList
							height={height}
							itemCount={people.length}
							itemSize={getRowHeight}
							ref={listRef}
							width={width}
						>
							{ContactRow}
						</VirtualList>
					)}
				</AutoSizer>
			)}

			{(loading || !people.length) && (
				<List>
					{loading && (
						<>
							<ConversationSkelton />
							<ConversationSkelton />
							<ConversationSkelton />
						</>
					)}

					{!loading && !people.length ? (
						<>
							<ListItem button disabled>
								<ListItemText primary={<i>&nbsp;No people yet</i>} />
							</ListItem>
						</>
					) : (
						''
					)}
				</List>
			)}

			{/* {!loading &&
					people.map(({ id, name, email, phoneNum }) => (
						<React.Fragment key={id}>
							<ListItemLink
								key={id}
								button
								href={`#/people/${id}`}
								className={clsx(styles.person)}
							>
								<div className={styles.avatarWrap}>
									<PersonAvatar person={{ name }} inbound={true} />
								</div>
								<div className={styles.infoWrap}>
									<div className={styles.topWrap}>
										<div className={styles.name}>{name}</div>
									</div>
									<div className={styles.previewWrap}>
										{formatPhone(phoneNum)} {email && phoneNum && <>&middot;</>}{' '}
										{email}
									</div>
								</div>
							</ListItemLink>
						</React.Fragment>
					))} */}

			<AddConvoDialog
				open={addConvoDialogOpen}
				onSave={onAddConvoSave}
				onCancel={onAddConvoCancel}
			/>
			<Zoom
				in={true}
				timeout={{
					enter: 300,
					exit: 300,
				}}
				unmountOnExit
			>
				<Tooltip title="New person">
					<Fab
						size="medium"
						onClick={openAddConvoDialog}
						className={styles.addConvoFab}
						ref={btnRef}
					>
						<AddIcon />
					</Fab>
				</Tooltip>
			</Zoom>
		</div>
	);
}
