import path from 'path';
import chalk from 'chalk';
import stackTrace from 'stack-trace';

const LogLevel = {
	Info: 'INFO',
	Debug: 'DEBUG',
	Warn: 'WARN',
	Error: 'ERROR',
};

// "Random" max length of codeLocation string - trims long filenames
// const MAX_LENGTH = 22;

// safeLog protects against runtime problems in node 8.11
/* eslint-disable no-console */
const failedMethods = {};
const safeLog = (method, ...args) => {
	if (method === 'debug') {
		// eslint-disable-next-line no-param-reassign
		method = 'info'; // debug method does not exist in node 8.11
	}

	const fn = console[method];
	if (typeof fn === 'function') {
		fn.apply(console, args);
	} else {
		if (!failedMethods[method]) {
			failedMethods[method] = true;
			console.log('***** INTERNAL ERROR *****');
			console.log(
				`***** Logger tried to call method '${method}' on console, but that didn't exist, falling back to console.log...`,
			);
			console.log('***** INTERNAL ERROR *****');
		}

		console.log(...args);
	}
};
/* eslint-enable no-console */

class Logger {
	static setFilter(filterCallback) {
		this.filterCallback = filterCallback;
	}

	static log(level, ...args) {
		const { filterCallback } = this;
		// Meta data to use for logging
		const { method, color } = Logger.logLevelMeta(level);

		// For use in threads, we intercept log statements and pass to parent
		// so this utility takes the __loggerMeta (built in child thread)
		// and logs that explicitly, then skips the rest of the logic below
		const lastArg = args ? args[args.length - 1] : null;
		if (lastArg && lastArg.__loggerMeta) {
			const { output } = lastArg.__loggerMeta;
			// eslint-disable-next-line no-unused-vars
			const [date, pid, location, unusedLevel, ...outputArgs] = output;
			if (
				filterCallback &&
				!filterCallback(level, location, pid, ...outputArgs)
			) {
				return;
			}

			// We can't just pass .output to console like console.log(...output)
			// because the chalk colors don't come thru from the child thread.
			// Therefore, we have to explicitly extract the fields (above)
			// and re-apply chalk colors to match output from this thread
			safeLog(
				method,
				chalk.grey(date),
				chalk.grey(pid),
				chalk.cyan(location),
				`[${chalk[color](level)}]`,
				...outputArgs,
			);

			return;
		}

		// Get location (outside of Logger) where the log originated
		let filepath;
		let line;
		let trace = stackTrace.get();
		if (!Array.isArray(trace) && typeof trace === 'string') {
			const list = trace.split('\n');
			const match = list.find(
				(test) => test.trim().startsWith('at') && !test.includes(__filename),
			);

			const idx1 = match.indexOf('(');
			const idx2 = match.indexOf(')');
			const fileAndLine = match.substring(idx1 + 1, idx2 - 1);
			[filepath, line] = fileAndLine.split(':');

			// console.log(`string trace debug:`, { match, idx1, idx2, fileAndLine });
		} else {
			const caller = trace.find(
				(data) => data && data.getFileName && data.getFileName() !== __filename,
			);
			filepath = caller.getFileName();
			line = caller.getLineNumber();
		}
		let file = path.basename(filepath || '');

		// index.js is not helpful, so replace with containing folder name
		if (file === 'index.js') {
			file = path.basename(filepath.replace(/\/index.js$/, ''));
		}

		// Build short string for file location and line number
		const codeLocation = `${file}:${line}`; // + '@' + caller.getFunctionName(); // TBD do we need function name in logs?
		// const codeLocationShort =
		// 	(codeLocation.length > MAX_LENGTH ? '…' : '') +
		// 	codeLocation.substring(
		// 		codeLocation.length - MAX_LENGTH,
		// 		codeLocation.length,
		// 	);
		// .padStart(MAX_LENGTH, ' ');

		if (
			filterCallback &&
			!filterCallback(level, codeLocation, process.pid, ...args)
		) {
			return;
		}

		// Build output - in the future, could send to cloud or Sentry, etc
		const output = [
			chalk.grey(`(PID ${process.pid})`),
			chalk.grey(new Date().toISOString()),
			// Make all log-levels same string length for nice alignment with padEnd()
			// "[" + chalk[color](level.padEnd(DEBUG.length, ' ')) + "]",
			`[${chalk[color](level)}]`,
			chalk.grey(`${codeLocation}:`),
			// // Actual user's log statement
			// ...args,
		];
		const message = args.shift();
		if (message && typeof message === 'string') {
			output.push(chalk[color](message));
		} else {
			output.push(message);
		}

		output.push(...args);

		// Allow interceptor to grab stuff before hitting console
		if (this.onLog) {
			const success = this.onLog(level, ...args, {
				__loggerMeta: {
					output,
				},
			});
			if (success) {
				return;
			}
		}

		// For now, just dump to console
		safeLog(method, ...output);
	}

	static logMultiline(level, string) {
		const lines = string.split('\n');

		// Using .forEach breaks the stackTrace, so use plain loop
		for (let i = 0; i < lines.length; i++) {
			this.log(level, lines[i]);
		}
	}

	static logLevelMeta(level) {
		const { Info, Debug, Warn, Error } = LogLevel;

		const method =
			{
				[Error]: 'error',
				[Warn]: 'warn',
				[Info]: 'log',
				[Debug]: 'debug',
			}[level] || 'log';

		const color =
			{
				[Error]: 'red',
				[Warn]: 'yellow',
				[Info]: 'green',
				[Debug]: 'cyan',
			}[level] || 'green';

		return { method, color };
	}

	static error(...args) {
		this.log(LogLevel.Error, ...args);
	}

	static warn(...args) {
		this.log(LogLevel.Warn, ...args);
	}

	static info(...args) {
		this.log(LogLevel.Info, ...args);
	}

	static debug(...args) {
		this.log(LogLevel.Debug, ...args);
	}

	static e(...args) {
		this.log(LogLevel.Error, ...args);
	}

	static w(...args) {
		this.log(LogLevel.Warn, ...args);
	}

	static i(...args) {
		this.log(LogLevel.Info, ...args);
	}

	static d(...args) {
		this.log(LogLevel.Debug, ...args);
	}

	static getPrefixedCustomLogger(prefix) {
		const wrap = (type) => (message, ...args) => {
			const string =
				message instanceof Error
					? `${message.message}\n${message.stack}`
					: message;

			return Logger.log(type, `${prefix} ${string}`, ...args);
		};

		return {
			error: wrap(LogLevel.Error),
			warn: wrap(LogLevel.Warn),
			info: wrap(LogLevel.Info),
			debug: wrap(LogLevel.Debug),
		};
	}

	static alert(label, args) {
		// TODO
		Logger.warn(`Unhandled ALERT: ${label}`, args);
	}
}

export default Logger;

Logger.LogLevel = LogLevel;
