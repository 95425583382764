import React from 'react';
import Head from 'components/Head';
// import styles from './PeopleListPage.module.scss';
import { NavConfig, NavControlService } from '../PrimaryNav';
import HubService from '../../utils/hub/HubService';
import PeopleList from '../PeopleList/PeopleList';

export default function PeopleListPage() {
	React.useEffect(() => {
		NavControlService.showPrimaryNav(true, NavConfig.settings);
	});

	// If still loading connection, returns undefined.
	// If loaded but LoginRequired, redirects to /login (leaves this page)
	// If loaded and authorized, returns true
	HubService.useAuthRequired();

	const { name } = HubService.useWorkspace();

	return (
		<>
			<Head title={`People - ${name}`} />
			<PeopleList />
		</>
	);
}
