export default class HistoryHack {
	static ref = {};

	static push(...args) {
		const { ref } = this;
		if (ref && ref.push) {
			ref.push(...args);
		} else {
			/* eslint-disable-next-line no-console */
			console.warn(
				'HistoryHack push called with no ref.push set, trying to set location manually',
			);
			window.location.href = `#${args[0]}`;
		}
	}
}
