// //////////////////////////////////////////////////////////////////////
// This file is generated in package.json under scripts/prebuild:config
// To change values here, don't edit this file. Edit /app.config.js
// and rerun 'npm run prebuild:config'
// //////////////////////////////////////////////////////////////////////
const path = require('path');

// Setup the env to match what we built with
Object.assign(process.env, {
	NODE_ENV: 'production',
	CHATBETTER_BUILD_ENV: 'prod',
});

// Export config for the above env
const AppConfig = {
	tcpPort: 8090, 

	udpPort: 54377, 

	fbAppId: '676798773071231', 

	fbAppSecret: '3012284d3750af13e8680fbb4034b190', 

	doctl: {
	"token": "ba3740b8c5ad3ff8f73fe6b80522427b10775a892e855edb150a70eb7208de14"
}, 

	doSpaces: {
	"accessKeyId": "3GNVVPVE6SV32WJE3T3W",
	"secretAccessKey": "BYhJVbyGxWfZcCNDHGYmbM9y0fklEBGynbCekaFoKoI",
	"endpoint": "sfo3.digitaloceanspaces.com",
	"urlBase": "https://files.chatbetter.io",
	"spaceName": "chatbetter"
}, 

	redis: {
	"url": "rediss://default:d7al17k0u9eiy36m@private-chatbetter-redis-do-user-131382-0.b.db.ondigitalocean.com:25061"
}, 

	iceServers: [
	{
		"urls": "stun:stun.l.google.com:19302"
	},
	{
		"urls": "stun:turn.vegasfire.josiahbryan.com:3478"
	},
	{
		"url": "turn:turn.vegasfire.josiahbryan.com",
		"username": "vegasfire",
		"credential": "af52890992352818493be47021c44325649761de9e9a1a2a82569dceaf2899e"
	}
], 

	apiHost: 'api.chatbetter.io', 

	internalApiHost: 'localhost:8090', 

	pinExpiration: 3600000, 

	iosClientId: 'com.chatbetter.camera', 

	fcmServerKey: 'AAAARFkB3T0:APA91bHl6bBrE-6agS1ONIPh2bN-_FTX82u8gXvmD9e2kC8Qs03CcuLVOV6fnC-xccb-Bn7R3642XUNPmKBGd4S3AxQ7JYTuJ6mR2J36_dbufBDyZBSOGC2bX0Z5-18FPw18AXPSp1GK', 

	firebaseKey: 'resources/sakeys/chatbetter-ade5e-firebase-adminsdk-xiv20-0cdba74d94.json', 

	firebaseWorkspaceId: 'chatbetter-ade5e', 

	firebaseStorageUrl: 'gs://chatbetter-ade5e.appspot.com', 

	mailgunKey: 'c6546f0a84f39784da6d84bdebd664b8-e49cc42c-3bd1405d', 

	twilioAccountSid: 'ACdbb67ce2eafe2cf74b09e47a14db2947', 

	twilioAuthToken: '5bca70eda35b1b036e6db6a931ced0dc', 

	twilioPhoneNum: '+15127102117', 

	sentryHubDsn: '....', 

	sentryCameraDsn: '...', 

	wwwRoot: path.resolve('../resources/www'), 

	dataRoot: path.resolve('../resources/data'), 

	logRoot: '/var/log/chatbetter/', 

	jsonPrettyPrint: 4, 

	hotfixUriFileBase: 'hub-hotfix', 

	clientSecret: '21e0dbe6-7b91-4cc6-b9d7-90f9542da958', 

	encryptSecret: '"94FootBattleSurprise31', 

	jwtPubKey: '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjVPlflJTFlLY+VL2t7kx\n4s9c32Vsxrx1Rq+vtqVOhjhL2PXIratQ/4/NPMIkqcHHFm1P8Taa0NcCnT877MEs\n1UzDD7Jyf08jsEPQRF9snxzmKRpqUXtBu8ZAlfdBEsfpdN9NSAuaguED3LZMQ0Pj\npBI4zb95FqriA0G2ILMyGNIQkkTB5k8p15qqXCy2LgDjUb2+NTL0RoE4ZHd4czIu\nx7xLMtEtF58bBtlGEokagOYvHe45aRI3sgjLMHIxOgMBvNF9tC0mtZAfDQetHwNW\nKyZwgjvkxR2snKxTqg8Mi8KW80Kz8lZT3T1CALOzoCV2Df291xMGpGAK4DMWezl8\naQIDAQAB\n-----END PUBLIC KEY-----', 

	jwtSignKey: 'eimeilahquah6raigooNgaichahgh0queishoh2eiphaeneebu9ahzu9AhyeiNaefeu3TielaiLa6cahm7befiko7IheiXiv0Aethichaekai2taeb4ohnab9Ohkoid5', 

	version: '1.0.0-prod',

	gitVersion: 'bf8ba73',

	buildEnv: 'prod',

	buildTime: '2021-05-16T04:07:15.353Z',

	buildArch: 'armv7l',

	buildPlatform: 'linux',

	// Generated as an MD5 of package-lock.json so we can tell if hotfix will work or not
	depHash: 'db3499663025e2078156804d94c79731',

	// Set in Jenkins via the BUILD_NUMBER env var
	buildNumber: 'undefined',
}

export default AppConfig;
