import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Head from 'components/Head';
import styles from './ConversationsListPage.module.scss';
import { NavConfig, NavControlService } from '../PrimaryNav';
import ConversationsList from '../ConversationsList/ConversationsList';
import HubService from '../../utils/hub/HubService';
import history from '../../utils/history';
import ConversationWidget from '../ConversationPage/ConversationWidget';

export default function ConversationsListPage() {
	const theme = useTheme();
	const showMessagePane = useMediaQuery(theme.breakpoints.up('sm'));

	React.useEffect(() => {
		NavControlService.showPrimaryNav(true, NavConfig.conversations);
	});

	// If still loading connection, returns undefined.
	// If loaded but LoginRequired, redirects to /login (leaves this page)
	// If loaded and authorized, returns true
	HubService.useAuthRequired();

	const { name } = HubService.useWorkspace();
	const [conversationId, setConversationId] = useState();

	const onConvoSelected = (conversation) => {
		const { id } = conversation;
		if (showMessagePane) {
			setConversationId(id);
		} else {
			history.push(`/conversation/${id}`);
		}
	};

	return (
		<div className={styles.root}>
			<Head title={`${name || 'Conversations'}`} />
			<ConversationsList
				onConvoSelected={onConvoSelected}
				doublePaneView={showMessagePane}
			/>
			{showMessagePane ? (
				<ConversationWidget
					conversationId={conversationId}
					doublePaneView={true}
				/>
			) : (
				''
			)}
		</div>
	);
}
