// src/components/PrivateRoute.js

// import React, { useEffect } from 'react';
import React from 'react';
import { Route } from 'react-router-dom';
import history from 'utils/history';
import HubService from '../utils/hub/HubService';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
	const isAuthenticated = !!HubService.useAuthorization();

	// const loadingGuard = React.useRef();
	// const [isAuthenticated, setIsAuthenticated] = React.useState();

	// useEffect(() => {
	// 	if (!loadingGuard.current) {
	// 		loadingGuard.current = true;
	// 		ServerStore.loginIfNeeded().then((user) => {
	// 			if (!user) {
	// 				// console.log("PrivateRoute: not authenticated, going to login");
	// 				history.push('/login');
	// 			} else {
	// 				// console.log("PrivateRoute: authenticated, allowing render");
	// 				setIsAuthenticated(true);
	// 			}
	// 		});
	// 	}
	// }, [setLoading, setIsAuthenticated, loading]);

	const render = (props) => {
		if (!isAuthenticated) {
			// eslint-disable-next-line no-console
			console.warn(
				`Private route encountered at ${path} but not logged in, redirecting...`,
			);
			history.push('/login');
			return <></>;
		}

		return <Component {...props} />;
	};

	return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
