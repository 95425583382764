import { std, mean } from 'mathjs';
import { KalmanFilter } from 'kalman-filter';
import { defer } from './defer';
import MessageTypes from './MessageTypes';

// Arbitrary magic ...
const MAX_OFFSETS = 7;

const PRIME_TIME_MS = 750;

const filteredMean = (data) => {
	const sorted = data.sort((a, b) => a - b).filter((x) => x !== undefined);
	if (sorted.length === 0) {
		return undefined;
	}
	// Remove outliers - e.g. measurements greater than one std from mean
	const singleStd = std(sorted);
	const origMean = mean(sorted);
	const filtered = sorted.filter((x) => Math.abs(origMean - x) <= singleStd);
	return mean(filtered);
};

export default class TimeSyncUtil {
	constructor(sendMessageHook) {
		this.sendMessageHook = sendMessageHook;

		// Just for testing
		// window.timeSyncUtil = this;
	}

	receivedTimeSync(data) {
		// Note: This stamping of the received time is crucial
		// to the measurement calculations below
		data.clientRxTime = Date.now(); // eslint-disable-line no-param-reassign

		if (this.timePromise) {
			this.timePromise.resolve(data);
			this.timePromise = null;
		}
	}

	async testGuess() {
		if (this.timePromise) {
			await this.timePromise;
		}

		this.timePromise = defer();

		const clientGuess = this.currentTime();
		this.sendMessageHook({
			type: MessageTypes.TimeSyncMessage,
			clientTxTime: Date.now(),
			subType: 'guess',
			clientGuess,
		});

		const guessResponse = await this.timePromise;
		// eslint-disable-next-line no-console
		console.log(`[TimeSyncUtil.testguess] guessResponse:`, guessResponse);
	}

	/**
	 * Gets the raw time message from the server by timestamping both the transmission
	 * (clientTxTime) and the reception (clientRxTime), returning the message object
	 * with the included serverTime from the server. Not used directly by consumers,
	 * but rather consumed internally by `getServerOffset` and other sync routines below.
	 * Note: This is required for TimeSyncUtil to work
	 */
	async exchangeTimeSync() {
		if (this.timePromise) {
			await this.timePromise;
		}

		this.timePromise = defer();
		if (this.sendMessageHook) {
			this.sendMessageHook({
				type: MessageTypes.TimeSyncMessage,
				clientTxTime: Date.now(),
				// Include guestimation for measuring how far off our current delta is
				clientGuess: this.currentTime(),
			});
		} else {
			// eslint-disable-next-line no-console
			console.error(
				`No sendMessageHook provided, cannot send time sync request`,
			);
		}
		return this.timePromise;
	}

	currentTime() {
		return Date.now() + this.serverTimeOffset;
	}

	timeInfo() {
		const serverTime = this.currentTime();
		const localTime = Date.now();
		const localStr = new Date(localTime).toISOString();
		const serverStr = new Date(serverTime).toISOString();
		return {
			serverTime,
			localTime,
			serverStr,
			localStr,
		};
	}

	/**
	 * This is the core of the time sync algo - it measures the latency of the connection,
	 * and factors in the latency to compensate for the difference between client and server
	 * clocks, then returns a delta which can be used to calculate the server time
	 * based on current client time, like `const serverTime = Date.now() + timeDelta`
	 */
	async getServerOffset({ includeTestResult } = {}) {
		const data = await this.exchangeTimeSync();

		/*

		Visual Explanation

		Client   Ctx  Cz   Crx
		---------*----x----*-------->
				  \       / \
				   \     /   \
					\   /     \
					 \ /       \
		--------------*---------*--->
		Server        St        St2

		Assumptions/descriptions for above visual:
			* Ctx - Client Tx Time (time client sent query to server)
				For discussion, let's say Ctx = 10
			* Crz - CLient Rx Time (time client received response from server)
			* Cz - halfway point between Crx and Ctx
			* St - Timestamp when server received and responded (stamped by server)
			* St2 - Timestamp when server received client's test estimation

		Based on those definitions:
			* Latency is Crx - Ctx
			* The halfway point (Cz) should therefore be the exact moment *on the client*
				at which the server stamped St
			* Therefore, by taking St - Cz, we get the difference (time offset) between client and server
				* Positive value means server (St) is ahead of client (Cz)
			* To send the time back to the server (to check or for other events),
				we have to account for the 2nd leg of latency (Crx->St2)
			* Therefore, St2 = St + (St-Cz) + (Crz-Cz)
				* This assumes Cz - Ctx = Crz - Ctz

		*/

		// This is basically point #3 from https://github.com/enmasseio/timesync#algorithm
		// but modified based on my own theories and with the addition of a LQE (Kalman)
		// filter for the outbound latency model
		const { clientRxTime, clientTxTime, serverTime, serverClientDelta } = data;

		// This is Crx - Ctx in the above
		const clientDelta = clientRxTime - clientTxTime;
		const latency = clientDelta / 2;

		// Original model, refactored 12/28/20 to the model below.
		// const serverDelta = clientRxTime - serverTime;
		// const timeDelta = serverDelta + latency;

		// The simple half-delta model for latency (clientDelta/2) assumes
		// the same latency for both transmit and receive. It also doesn't
		// compensate for variations between tests or other interferences (e.g.
		// signal multipath, etc, in the case of WiFi.) So we've introduced a
		// LQE filter (Kalman) to model the hidden variables we can't directly
		// measure and provide us with a "cleaned up" latency value. We'll use
		// this "predicted latency" rather than the measured latency
		// to better model our time difference.
		const filteredLatency = this.getFilteredLatency(latency);

		// clientCenterTime models the time it was on the client
		// the moment the server stamped the 'serverTime' value,
		const clientCenterTime = clientRxTime - filteredLatency;
		// We can get the absolute clock difference from server to client
		// by subtracting the time it was on the client when the server stamped serverTime
		// from serverTime itself, giving us a positive value when server is ahead of the client.
		const serverDelta = serverTime - clientCenterTime;
		// The whole point of this TimeSyncUtil is to model the predicted time on the server,
		// and to do that, we must compensate for not only outbound/inbound latency during
		// measurements but also compensate for latency in the retransmission of that timestamp.
		// We could store the serverDelta and latency as two separate values and add them
		// together in currentTime(), but then we would have to rework updateBestOffset(),
		// so for now, we will store the delta + latency as a single value.
		const timeDelta = serverDelta + filteredLatency;

		// Logger.debug(`server offset debug:`, {
		// 	clientRxTime,
		// 	clientTxTime,
		// 	serverTime,
		// 	clientDelta,
		// 	latency,
		// 	serverDelta,
		// 	timeDelta,
		// });

		if (includeTestResult) {
			return { timeDelta, serverClientDelta };
		}

		return timeDelta;
	}

	// Added a Kalman filter (https://en.wikipedia.org/wiki/Kalman_filter)
	// using 'kalman-filter' (https://www.npmjs.com/package/kalman-filter)
	// in online mode to filter model the outgoing latency from client>server
	getFilteredLatency(observation) {
		if (!this.kFilter) {
			this.kFilter = new KalmanFilter();
		}

		const { kPrevious: previousCorrected } = this;
		this.kPrevious = this.kFilter.filter({ previousCorrected, observation });

		return parseFloat(this.kPrevious.mean);
	}

	/**
	 * This is a single-call function to get multiple offsets from the server and calculate
	 * the "best" offset to use. It does this by measuring the latency multiple times,
	 * then taking the stddev, eliminating outliers, then taking the avg delta as the "best."
	 * This is fine for testing, not sure if client will use anything like this.
	 *
	 * @returns {number} the best delta offset at the time of measurement
	 */
	async getBestOffset(maxTimeMs = PRIME_TIME_MS, includeTestResult) {
		// poll 3-5 x (or set a max time)
		// get stddev, elimite outliers, take mean
		const tests = [];
		const start = Date.now();
		const max = start + maxTimeMs;

		const measure = async () => {
			if (Date.now() > max) {
				return false;
			}

			const { serverClientDelta, timeDelta } = await this.getServerOffset({
				includeTestResult,
			});
			tests.push({ serverClientDelta, timeDelta });

			// Wait a small amount of time before querying the server again so as not to flood
			// the server and/or connection with too many requests too quickly
			await new Promise((resolve) =>
				setTimeout(resolve, 100 + Math.random() * 50),
			);

			return measure();
		};

		await measure();

		// Extract time deltas and get filtered mean
		const measurements = tests.map((t) => t.timeDelta);
		const bestOffset = filteredMean(measurements);

		// Extract test responses and get filtered mean
		const serverClientDeltas = tests.map((t) => t.serverClientDelta);
		const deltaTestMean = filteredMean(serverClientDeltas);

		// Logger.debug('Measurements:', measurements, {
		// 	singleStd,
		// 	origMean,
		// 	bestMeasurements,
		// 	bestOffset,
		// });

		this.serverTimeOffset = bestOffset;
		this.serverDeltaTestMean = deltaTestMean;

		return bestOffset;
	}

	/**
	 * This, I imagine, will be more like what the client will use. This function
	 * maintains a rolling buffer of deltas in this.offsetMeasurements, and only keeps the last
	 * MAX_OFFSETS deltas in the buffer, discarding the oldest. It measures the delta one time
	 * from the server, adds the delta to the buffer, then takes the stddev of the buffer,
	 * eliminates outliers more than 1 stddev from mean, then takes the avg of the buffer
	 * as the "best" delta offset.
	 *
	 * Call this function repeatedly to prime the buffer, or call it on an interval to keep the
	 * "best" delta offset updated with changing/drifting clock differences.
	 *
	 * @returns {number} the best delta offset at the time of measurement
	 */
	async updateBestOffset({ disableAutoReprime } = {}) {
		// poll 3-5 x (or set a max time)
		// get stddev, elimite outliers, take mean

		if (!this.offsetMeasurements) {
			this.offsetMeasurements = [];
		}

		const data = await this.getServerOffset({ includeTestResult: true });
		this.offsetMeasurements.push(data);
		while (this.offsetMeasurements.length > MAX_OFFSETS) {
			this.offsetMeasurements.shift();
		}

		// Slice to make sure we shift off the oldest, otherwise sort mutates original array
		const tests = this.offsetMeasurements.slice();

		// Extract time deltas and get filtered mean
		const measurements = tests.map((t) => t.timeDelta);
		const bestOffset = filteredMean(measurements.slice());

		// Extract test responses and get filtered mean
		const serverClientDeltas = tests.map((t) => t.serverClientDelta);
		const deltaTestMean = filteredMean(serverClientDeltas.slice());

		// Get one std dev for checking to see if the test drifted and needs re-primed
		const stdMeasured = std(measurements);

		// console.log('[TimeSyncUtil:updateBestOffset]', measurements, {
		// 	tests,
		// 	stdMeasured,
		// 	bestOffset,
		// 	deltaTestMean,
		// });

		if (!disableAutoReprime && Math.abs(deltaTestMean) > stdMeasured) {
			// console.warn(
			// 	`Delta Test came back greater than 1 std measured delta, re-priming...`,
			// );
			this.primeBestOffset();
		}

		this.serverTimeOffset = bestOffset;
		this.serverDeltaTestMean = deltaTestMean;

		return bestOffset;
	}

	/**
	 * This function "primes" the "best" offset by calling `updateBestOffset` multiple times,
	 * either until maxTimeMs has passed or MAX_OFFSETS measurements have been taken.
	 * @returns {number} the best delta offset at the time of measurement
	 */
	async primeBestOffset(maxTimeMs = PRIME_TIME_MS) {
		const start = Date.now();
		const max = start + maxTimeMs;
		let count = 0;

		const measure = async () => {
			if (count++ >= MAX_OFFSETS || Date.now() > max) {
				return false;
			}

			await this.updateBestOffset({ disableAutoReprime: true });

			// Wait a small amount of time before querying the server again so as not to flood
			// the server and/or connection with too many requests too quickly
			await new Promise((resolve) =>
				setTimeout(resolve, 50 + Math.random() * 50),
			);

			return measure();
		};

		await measure();

		return this.serverTimeOffset;
	}
}
