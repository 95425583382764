import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './ValueEditDialog.module.scss';

export default function ValueEditDialog({ options, open, onSave, onCancel }) {
	const { field, label, type, value, saveLabel, cancelLabel } = options;
	const [newValue, setNewValue] = React.useState();

	const onClose = () => onCancel && onCancel();
	const saveChanges = () => onSave && onSave({ [field]: newValue });

	// console.warn(`ValueEditDialog got opts:`, options);

	return (
		<Dialog className={styles.root} open={open || false} onClose={onClose}>
			<DialogTitle>{label}</DialogTitle>
			<DialogContent className={styles.fields}>
				<TextField
					type={type || 'text'}
					label={label}
					autoFocus
					defaultValue={value || ''}
					onChange={(ev) => setNewValue(ev.target.value)}
				/>
			</DialogContent>
			<DialogActions className={styles.actions}>
				<Button onClick={onClose} color="default">
					{cancelLabel || 'Cancel'}
				</Button>
				<Button onClick={saveChanges} color="primary">
					{saveLabel || `Save ${label}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
