/* eslint-disable no-console */
import { useState, useEffect, useRef } from 'react';

// Used so we don't have to re-parse every load
const ParsedCache = {};

const CACHE_KEY_PREFIX = '@state-cache';
const PARSE_CACHE_EXPIRES = 1000 * 30; // 30sec
const STORE_CACHE_EXPIRES = 1000 * 60 * 1.5; // 1.5min

//
// TODO: Implement max bytes/keys to cap cache/storage at max size even  if not expired.
// const MAX_PARSED_KEYS = 1024;
// const MAX_STORE_BYTES = 1024 * 1024 * 1.5;

// This is a simple utility to extract the timestamp prefix we add to our json in the localStorage
// cache so we don't have to parse JSON every time just to tell if it's expired.
// We return null if no timestamp or NaN, etc - so corrupted timestamps can be detected,
// but we just treat them as expired/cache misses. Used both in expireCache() and in loadCachedData()
const parseTimestampData = (stringData, key) => {
	if (!stringData) {
		return null;
	}
	// Timestamp stored in the string after jsonification so we don't have to parse
	// JSON every time we expireCache()
	const tsIndex = stringData.indexOf(':');
	const timestamp = parseInt(stringData.substring(0, tsIndex), 10);
	if (Number.isNaN(timestamp)) {
		console.warn(`Invalid state cache timestamp:`, timestamp, {
			key,
			stringData,
		});
		return null;
	}

	const json = stringData.substring(tsIndex + 1); // everything AFTER the colon
	return [timestamp, json];
};

// expireCache() is a background function that is called on an interval, started
// by pingExpireCacheCron() which is pinged every time we loadCachedData().
// The whole purpose of expireCache() is to scan both our internal ParsedCache
// and the localStorage data and delete any keys that are older than the cutoff.
// We do this mainly so we don't fill localStorage (and RAM via ParsedCache)
// even if we use a lot of throw-away cache keys.
//
// TODO: Implement max bytes/keys to cap cache/storage at max size even  if not expired.
//
let expireCacheCronIntervalId;
const expireCache = () => {
	let dead = true;
	Object.entries(ParsedCache).forEach(([key, { timestamp }]) => {
		dead = false;
		if (Date.now() - timestamp > PARSE_CACHE_EXPIRES) {
			delete ParsedCache[key];
			console.warn(`ParsedCache cache stale for ${key}, deleting`);
		}
	});

	Object.entries(window.localStorage).forEach(([key, stringData]) => {
		if (!key.startsWith(`${CACHE_KEY_PREFIX}/`)) {
			return;
		}

		dead = false;
		const [timestamp] = parseTimestampData(stringData, key) || [];
		if (!timestamp || Date.now() - timestamp > STORE_CACHE_EXPIRES) {
			window.localStorage.removeItem(key);
			console.warn(`localStorage cache stale for ${key}, deleting`);
		}
	});

	if (dead) {
		clearInterval(expireCacheCronIntervalId);
		expireCacheCronIntervalId = null;
	}
};

const pingExpireCacheCron = () => {
	if (!expireCacheCronIntervalId) {
		expireCacheCronIntervalId = setInterval(expireCache, PARSE_CACHE_EXPIRES);
		expireCache();
	}
};

/**
 * Internal cache for the useInitializedStateCache() hook, below.
 * See comments there for when/why this is used.
 */
class InitializedStateCache {
	constructor(initialValue = null, initStateCallback = () => {}, persistKey) {
		this.initStateCallback = initStateCallback;
		this.internalState = initialValue;
		this.persistKey = persistKey && `${CACHE_KEY_PREFIX}/${persistKey}`;

		this.loadCachedData();
	}

	setupInternalHooks(state, setState, mountedGuard) {
		this.mountedGuard = mountedGuard;

		if (this.reactSetter !== setState) {
			this.reactSetter = setState;
			this.reactGetter = state;
		}

		// Since reactSetter is async (e.g. the value does not update
		// the reactGetter immediately - may take a few ticks,) therefore,
		// we use valueGuard (which does update immediately) to prevent us
		// from calling reactSetter more than once for the same cached value
		// because otherwise we could trigger a React update loop, which would
		// end up crashing the renderer - not good.
		if (
			this.valueGuard !== this.internalState &&
			this.reactGetter !== this.internalState
		) {
			this.reactSetter(this.internalState);
			this.valueGuard = this.internalState;
		}

		// This is where the state actually gets to call the initialization
		// callback. External users can also call .reloadState()
		// to manually reload the state with the callback (manually calling it
		// doesn't guard with the .loaded guard, only the .loading guard),
		// and external users can also affect the cached (and react) state
		// with the .setState() method.
		if (!this.loaded && !this.loading) {
			// Move into an animation frame so it hits AFTER loadCachedData triggers
			window.requestAnimationFrame(() => this.reloadState());
		}
	}

	// No corresponding setter is provided to prevent accidentally
	// setting the state, making bugs harder to find/reason.
	// TBD if we need a setter - if it makes life easier, then we can easily add.
	get state() {
		return this.internalState;
	}

	getState() {
		return this.internalState;
	}

	setState(value, fromCache = false) {
		this.internalState = value;
		if (this.mountedGuard && !this.mountedGuard.current) {
			return;
		}
		if (this.reactSetter) {
			this.reactSetter(value);
		}

		const { persistKey } = this;
		if (persistKey && !fromCache) {
			const timestamp = Date.now();
			ParsedCache[persistKey] = { data: value, timestamp };

			// Move out of main loop so we don't freeze render with JSON.stringify
			clearTimeout(this.cacheUpdateTid);
			this.cacheUpdateTid = setTimeout(() => {
				try {
					const json = JSON.stringify(value);
					window.localStorage.setItem(persistKey, `${timestamp}:${json}`);
				} catch (ex) {
					console.warn(`Error persisting state cache to ${persistKey}:`, ex);
				}
			}, 1000 / 33);
		}
	}

	loadCachedData() {
		const { persistKey } = this;
		if (!persistKey) {
			return;
		}

		pingExpireCacheCron();

		const { data, timestamp } = ParsedCache[persistKey] || {};
		if (timestamp && Date.now() - timestamp < PARSE_CACHE_EXPIRES && data) {
			this.initialStateCached = true;
			this.setState(data, true);
			ParsedCache[persistKey].timestamp = Date.now();
			return;
		}

		// Move to next frame to not block render with JSON.parse()
		// Not using requestAnimationFrame because that did not
		// give React time to render the Skeleton
		setTimeout(() => {
			try {
				const stringData = window.localStorage.getItem(persistKey);
				// eslint-disable-next-line no-shadow
				const [timestamp, json] =
					parseTimestampData(stringData, persistKey) || [];
				if (timestamp && Date.now() - timestamp < STORE_CACHE_EXPIRES && json) {
					// eslint-disable-next-line no-shadow
					const data = JSON.parse(json);
					this.initialStateCached = true;
					this.setState(data, true);

					// Cache above so we don't have to reparse
					ParsedCache[persistKey] = { timestamp: Date.now(), data };
				}
			} catch (ex) {
				console.warn(`Error loading ${persistKey} from cache:`, ex);
			}
		}, 1000 / 33);
	}

	async reloadState() {
		const { loading, initialStateCached } = this;
		if (loading) {
			return;
		}
		this.loading = true;
		this.initStateCallback({
			initialStateCached,
		}).then((value) => {
			this.setState(value);
			this.loading = false;
			this.loaded = true;
		});
	}
}

/**
 * This hook is basically like the basic React hook `useState()`, but I found
 * that initalizing the useState() hook with async values to be rather limiting.
 * Initially I used useAsyncParam() for that - which is good for read-only
 * values or values which only ever change on the server (which can be reloaded
 * into the state.) However, when you want to **initialize** a React state
 * async (and not do the async work on every render) and **also** be able to
 * do some sort of `setState` in your own code to modify the state value
 * without having to re-do the async work (e.g. server call) - well, to do that
 * usually ended up with lots of `useRef()` guards and jumping thru hoops to do
 * the same repeated code every time I wanted to do that in a different spot.
 * So, I packaged the repeated code into this hook and made it easy to use.
 *
 * Just pass in an async callback to initialize the state and you get
 * back an object (not array) with a `state` property (React state has
 * the latest value), a `setState` function to change the state however you want,
 * and a `reloadState` function that calls your callback and stores the results
 * internally (and updates the `state`, of course.) This hook also takes care
 * not to affect the React state if unmounted.
 *
 * @param {function} initCallback - Async function to "load" the value for the state - whatever that means to your code. The callback is awaited (or then()ed, or however you want to think of it) and the resulting value of that promise is used as the state. Errors are not caught, so catch your own errors inside your callback.
 * @param {any} initialValue - Initial value to set to the state before calling the initCallback - e.x. `[]` or `null` (defaults to `null`)
 * @returns {object} Object shaped like `{ state, setState, reloadState }`, where `state` is a React state variable, and `setState` and `reloadState` are functions that do exactly what they say.
 */
export default function useInitializedStateCache(
	initCallback = async () => {},
	{ persistKey = '', initialValue = null } = {},
) {
	const cache = useRef(
		new InitializedStateCache(initialValue, initCallback, persistKey),
	);

	const mountedGuard = useRef(false);
	useEffect(() => {
		mountedGuard.current = true;
		return () => {
			mountedGuard.current = false;
		};
	});

	const [state, setState] = useState();
	cache.current.setupInternalHooks(state, setState, mountedGuard);

	return cache.current;
}
